import requests from '../requests';

export const FETCH_KERNEL_REQUEST = 'FETCH_KERNEL_REQUEST';
export const FETCH_KERNEL_SUCCESS = 'FETCH_KERNEL_SUCCESS';
export const FETCH_KERNEL_FAILURE = 'FETCH_KERNEL_FAILURE';

function fetchKernelRequest() {
  return {
    type: FETCH_KERNEL_REQUEST
  };
}

function fetchKernelSuccess(payload) {
  return {
    type: FETCH_KERNEL_SUCCESS,
    payload,
  };
}

function fetchKernelFailure(payload) {
  return {
    type: FETCH_KERNEL_FAILURE,
    payload,
  };
}

export const fetchKernel = (args) => {
  return async dispatch => {
    dispatch(fetchKernelRequest());
    const response = await requests.kernel.get(args);    
    if (response.status < 400) {
      dispatch(fetchKernelSuccess(response));
    } else {
      dispatch(fetchKernelFailure(response));
    }
  };
};


export const RESTART_KERNEL_REQUEST = 'RESTART_KERNEL_REQUEST';
export const RESTART_KERNEL_SUCCESS = 'RESTART_KERNEL_SUCCESS';
export const RESTART_KERNEL_FAILURE = 'RESTART_KERNEL_FAILURE';

function restartKernelRequest() {
  return {
    type: RESTART_KERNEL_REQUEST
  };
}

function restartKernelSuccess(payload) {
  return {
    type: RESTART_KERNEL_SUCCESS,
    payload,
  };
}

function restartKernelFailure(payload) {
  return {
    type: RESTART_KERNEL_FAILURE,
    payload,
  };
}

export const restartKernel = (args) => {
  return async dispatch => {
    dispatch(restartKernelRequest());
    const response = await requests.kernel.restart(args);    
    if (response.status < 400) {
      dispatch(restartKernelSuccess(response));
    } else {
      dispatch(restartKernelFailure(response));
    }
  };
};

export const INTERRUPT_KERNEL_REQUEST = 'INTERRUPT_KERNEL_REQUEST';
export const INTERRUPT_KERNEL_SUCCESS = 'INTERRUPT_KERNEL_SUCCESS';
export const INTERRUPT_KERNEL_FAILURE = 'INTERRUPT_KERNEL_FAILURE';

function interruptKernelRequest() {
  return {
    type: INTERRUPT_KERNEL_REQUEST
  };
}

function interruptKernelSuccess(payload) {
  return {
    type: INTERRUPT_KERNEL_SUCCESS,
    payload,
  };
}

function interruptKernelFailure(payload) {
  return {
    type: INTERRUPT_KERNEL_FAILURE,
    payload,
  };
}

export const interruptKernel = (args) => {
  return async dispatch => {
    dispatch(interruptKernelRequest());
    const response = await requests.kernel.interrupt(args);    
    if (response.status < 400) {
      dispatch(interruptKernelSuccess(response));
    } else {
      dispatch(interruptKernelFailure(response));
    }
  };
};

export const UPDATE_KERNEL_STATE = 'UPDATE_KERNEL_STATE';

export const updateKernelState = (payload) => ({
  type: UPDATE_KERNEL_STATE,
  payload,
}); 
