import { store } from '../store/configureStore';

export const getStateUser = () => {
  try {
    const auth = store.getState().auth;
    return { id: auth.userId, token: auth.token };
  } catch (err) {
    console.error(err);
    return null;
  }
};
