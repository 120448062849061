import React from 'react'
import {NavLink} from 'react-router-dom'

export default() => (
  <div className="nav">
    <ul className="links">
      <li className="navli">
        <NavLink className="" to="/login" activeClassName="active">Sign In</NavLink>
      </li>
      <li className="navli">
        <NavLink className="" to="/register" activeClassName="active">Sign Up</NavLink>
      </li>
    </ul>
  </div>
);
