import request from '../utils/request';
import { getStateUser } from '../utils/store';

export const get = async ({ notebookName }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${notebookName}/kernel`;
  return await request(url);
};

export const restart = async ({ notebookName }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${notebookName}/kernel/restart`;
  return await request(url);
};

export const interrupt = async ({ notebookName }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${notebookName}/kernel/interrupt`;
  return await request(url);
};
