import { connect } from 'react-redux';
import WorkspaceHeader from '../components/Workspace/WorkspaceHeader';

import { 
  updateNotebook,
} from '../actions/notebooks';

const mapStateToProps = state => ({
  notebook: state.notebooks.active,
  cells: state.notebooks.cells,
  isUpdateNotebookRequest: state.notebooks.isUpdateNotebookRequest,
});

const mapDispatchToProps = dispatch => ({
  updateNotebook: args => dispatch(updateNotebook(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceHeader);
