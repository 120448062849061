import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone';
import AccountBox from '@material-ui/icons/AccountBox';
import MenuIcon from '@material-ui/icons/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import StopIcon from '@material-ui/icons/Stop';
import Autorenew from '@material-ui/icons/Autorenew';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import HourglassFull from '@material-ui/icons/HourglassFull';
import Account from '../../containers/Account';
import Kernel from '../../containers/Kernel';
import * as moment from 'moment';

const styles = theme => ({
  buttonFloatRight: {
    float: "right",
    padding: "6px",

    // margin: theme.spacing.unit,
  },
  typographyFloatLeft: {
    float: "left",
  },
  tabContainer: {
    padding: "0px 0px 0px 0px"
  },
  containerInfo : {
    padding : '5px',
    display : 'flex',
    flexDirection: 'column',
    marginTop : 'auto',    
    alignSelf : 'flex-end',
    justifyContent : 'center'

  },
  rightMenu : {
    display : 'flex',
    height : '100%',
    flexDirection : 'column',
  },
  
  button : {
    margin : '2px'
  },
  height100p : {
    height : '100%',
  },

});

class WorkspaceHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      right : false,
      account : false,
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.handleCloseAccount = this.handleCloseAccount.bind(this);

    // vusial handlers
    this.toggleDrawer = this.toggleDrawer.bind(this);    
    //
    this.handleUpdateNotebook = this.handleUpdateNotebook.bind(this);
  }

  handleUpdateNotebook() {
    const { 
      notebook, 
      cells, 
      updateNotebook, 
      isUpdateNotebookRequest 
    } = this.props;

    if (notebook && !isUpdateNotebookRequest) {
      const content = Object.assign(notebook.content, {});
      content.cells = cells;      
      updateNotebook({
        name: notebook.name,
        content: content,
      });
    }
  }

  handleLogout(e) {
    const { logout } = this.props;

    e.preventDefault();
    logout();
  }

  handleAccount(e) {
    this.setState({      
      account : true,
    });    
  }

  handleCloseAccount = () => {
    this.setState({
        account : false,
    })
};

  toggleDrawer = (side, open) => () => {
    this.setState({
      tabindex: 0,
      [side]: open,
    })
  }
  
  render() {
    const { classes, application, CONTAINER_RUNNING, user, notebook } = this.props;
    const {account} = this.state;

    const sideList = (
      <div className={classes.rightMenu}>
        <List>
            <ListItem button onClick={this.handleAccount}>
              <ListItemIcon><AccountBox /></ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>

            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon><ExitToAppTwoTone /></ListItemIcon>
              <ListItemText primary="Logout" />
          </ListItem>
        </List>
        <Divider />
        
        <Divider />
        
        
      </div>
    );

    return (
      
      <Grid container justify="space-between">      
        <Grid item xs={3} sm={3}>
          <Typography variant="h5" className={classes.typographyFloatLeft}>ML.Animarender</Typography>
        </Grid>
        <Grid item xs={9} sm={9} style={{ display: "flex", alignItems : 'center', justifyContent: 'flex-end' }}>
        {notebook ? <Kernel notebookName={notebook.name} /> : '' 
      }
        
        <IconButton color="primary"
                    disabled={!notebook}
                    title="Save notebook"
                    aria-label="Save"
                    onClick={this.handleUpdateNotebook}>
          <SaveIcon />
        </IconButton>
          <Button onClick={this.toggleDrawer('right', true)}>
            <MenuIcon/>
          </Button>            
        </Grid>
        <Grid item xs={12} sm={12}>
          {/* visualizing background work */}
          {application && application.state ===  'TASK_KILLING' ?
            <LinearProgress color="secondary"/> :""
          }
        </Grid>
        
        <Drawer anchor="right"
                open={this.state.right}
                onClose={this.toggleDrawer('right', false)}>
            <div tabIndex={0}
                className={classes.height100p}
                role="button"
                onClick={this.toggleDrawer('right', false)}
                onKeyDown={this.toggleDrawer('right', false)}>
                {sideList}
            </div>
        </Drawer>
        <Account openAccount={account}
                closeAccount={this.handleCloseAccount}
                user={user}/>

      </Grid>
      
      
    );
  }
}

export default withStyles(styles)(WorkspaceHeader);
