import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
} from '../actions/auth';
import { getLocalUser } from '../utils/auth';

const user = getLocalUser();
const initialState = {
  userId: user ? user.id : null,
  token: user ? user.token : null,
  isAuthenticated: !!user,
  isLoginRequest: false,
  isLoginSuccess: false,
  isLoginFailure: false,
  isRegisterRequest: false,
  isRegisterSuccess: false,
  isRegisterFailure: false,
  message : '',
};

export default (state=initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoginRequest: true,
        isLoginSuccess: false,
        isLoginFailure: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoginRequest: false,
        isLoginSuccess: true,
        isLoginFailure: false,
        isAuthenticated: true,
        userId: action.payload.data.id,
        token: action.payload.data.token,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoginRequest: false,
        isLoginSuccess: false,
        isLoginFailure: true,
        isAuthenticated: false,
        message : action.payload.message,
      };

    case REGISTER_REQUEST:
      return {
        ...state,
        isRegisterRequest: true,
        isRegisterSuccess: false,
        isRegisterFailure: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterRequest: false,
        isRegisterSuccess: true,
        isRegisterFailure: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        isRegisterRequest: false,
        isRegisterSuccess: false,
        isRegisterFailure: true,
      };
    case LOGOUT:
      return {
        userId: null,
        token: null,
        isAuthenticated: false,
        isLoginRequest: false,
        isLoginSuccess: false,
        isLoginFailure: false,
        isRegisterRequest: false,
        isRegisterSuccess: false,
        isRegisterFailure: false,
      };
    default:
      return state;
  }
}
