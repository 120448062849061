import React, { Fragment } from 'react';
import htmlToreact from 'html-to-react';
import * as AU from 'ansi_up';

const ansi_up = new AU.default();

class CellOutputs extends React.Component {
  renderAnsi(val) {
    try {
      const textHtml = ansi_up.ansi_to_html(val);
      const parser = new htmlToreact.Parser();
      return parser.parse(textHtml);
    } catch (err) {
      console.error(err);
      return val;
    }
  }

  renderHtml(val) {
    try {
      const parser = new htmlToreact.Parser();
      return parser.parse(val)
    } catch (err){
      console.error(err);
      return val;
    }
  }

  render() {
    const { outputs, cellId } = this.props;

    return (
      outputs && outputs.length
        ? outputs.map((output, index) => (          
          <div key={`cell_${cellId}_output_${index}`}>
            {(output.outputType === 'error') 
              ? <Fragment>
                  <div><b>{output.ename}</b> {output.evalue}</div>
                  <pre>
                    {output.traceback && output.traceback.map((traceback, index) => (
                      <div key={index}>{this.renderAnsi(traceback)}</div>
                    ))}
                  </pre>
                </Fragment>
              : (output.data && output.data['image/png'])
                ? <img src={`data:image/png;base64,${output.data['image/png']}`} alt="" />
                : (output.text || (output.data && output.data['text/plain']))
                  ? (output.data && output.data['text/html']) ? 
                  <div>{this.renderHtml(output.data['text/html'])}</div>
                  :                  
                  <pre>{output.text || output.data['text/plain']}</pre>
                  : 'executed'
            }
          </div>))
        : ""        
    );
  }
}

export default CellOutputs;
