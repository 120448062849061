import axios from 'axios';
import * as config from '../config.json';
import { store } from '../store/configureStore';
import { logout } from '../actions/auth';
import { serviceUnavailable } from '../actions/application';
import { getStateUser } from './store';
import { objectKeysToCamelCase } from './object';

axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'XSRF-TOKEN';
axios.defaults.timeout = 1000 * 3600;

export default async(url, method = 'GET', data = undefined) => {
  const headers = {};

  // Setting JWT if him exist
  const user = getStateUser();
  if(user && user.token) {
    headers['Authorization'] = `Bearer ${user.token}`;
  }

  if (data instanceof FormData) {
    headers['Content-type'] = 'application/x-www-form-urlencoded';
  } else {
    headers['Content-Type'] = 'application/json';
    if (data) {
      data = JSON.stringify(data);
    }
  }

  url = url.startsWith(config.apiProtocol)
    ? url
    : `${config.apiProtocol}://${config.apiHost}/${config.apiPrefix}/${url}`;

  let response;
  try {
    response = await axios({ url, method, headers, data });
  } catch (err) {
    if (err.response) {
      response = err.response;
    } else {
      console.error('Unknown response err: ', err);
      return { status: 500, message: `Unknown response err: ${err}` };
    }
  }

  if (response.status === 401) {
    store.dispatch(logout());
  }
  else if (response.status === 503) {
    store.dispatch(serviceUnavailable());
  }

  if (response.status && !response.data) {
    return { status: response.status, data: null };
  }

  try {
    return objectKeysToCamelCase(response.data);
  } catch (err) {
    console.error('Parse response err: ', err);
    return { status: 500, data: null, message: `Parse response err: ${err}` };
  }
};
