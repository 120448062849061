import './Home.css';
import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ws from '../../ws';
import Workspace from '../../containers/Workspace';

class Home extends Component {

  componentDidMount() {
    const { userId, token, fetchUser } = this.props;

    ws.start(token);
    fetchUser(userId);
  }

  render() {
    const { user } = this.props;

    return (
      <div className="home">
        <CSSTransitionGroup transitionName="fade"
                            transitionAppear={true}
                            transitionAppearTimeout={500}
                            transitionEnter={false}
                            transitionLeaveTimeout={300}>
          <Workspace key="lm" user={user} />
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withTheme()(Home);
