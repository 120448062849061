import { connect } from 'react-redux';
import Home from '../components/Home/Home';
import { fetchUser } from '../actions/user';

const mapStateToProps = state => ({
  userId: state.auth.userId,
  user: state.user.data,
  token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: (userId) => dispatch(fetchUser(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
