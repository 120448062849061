import React, {Component} from 'react'
import {Redirect} from 'react-router-dom';
import AuthNav from '../AuthNav/AuthNav';
import {NavLink} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  main: {
    width: 'auto',
    display: 'flex', // Fix IE 11 issue.
    flexDirection : 'column',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },  
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    height : 'fit-content',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  footer : {
    display : 'flex',
    marginTop : 'auto',
    textAlign : 'center',
  },
  error : {
    textAlign : 'center',
    background : theme.palette.secondary.main,
    color : 'white',
    width : '100%',
    marginBottom : '15px',
    padding : '5px',
  },
});

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: {
        value: '',
        isValid: true,
        isValidated: false
      },
      password: {
        value: '',
        isValid: true,
        isValidated: false
      },
      allowRedirect : false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  validateField(name, value) {
    let isValid = this.state[name].isValid;
    let isValidated = this.state[name].isValidated;

    switch (name) {
      case 'email':
        isValid = /[^@]+@[^@]+\.[^@]+/.test(value);
        isValidated = true;
        break;
      case 'password':
        isValid = !!value.length;
        isValidated = true;
        break;

      default:
        break;
    };

    this.setState({ 
      ...this.state,
      [name]: {
        ...this.state[name],
        isValid,
        isValidated,
      }
    });
  } 

  handleChange(e) {
    const { name, value } = e.target;
    const state = Object.assign({}, this.state);

    state[name].value = value;
    this.setState(state, () => this.validateField(name, value));
  }

  handleSubmit(e) {
    e.preventDefault();
    const { register } = this.props;
    const { email, password } = this.state;
    const {isRegisterSuccess} = this.props;

    register(email.value, password.value);
    
  }

    

  render() {
    const { email, password } = this.state;
    const { isAuthenticated, isRegisterSuccess, isRegisterFailure, classes } = this.props;

    if(isAuthenticated) return <Redirect to="/"/>
    if(isRegisterSuccess) return <Redirect to="login" />

    return (
      <main className={classes.main}>
          <Paper className={classes.paper}>
            {
              isRegisterFailure ? <Typography className={classes.error}>User already exist</Typography> : ""
            }
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Registration</Typography>          
            <form onSubmit={this.handleSubmit} className={classes.form}>
            <FormControl  required
                            error={!email.isValid}
                            fullWidth
                            margin="normal"                            
                            aria-describedby="username-error-text">
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <Input id="email" 
                       name="email" 
                       autoFocus
                       autoComplete="email"
                       onChange={this.handleChange} />
                {/* {isLoginFailure ? <FormHelperText id="username-error-text">Error</FormHelperText> : ""}                 */}
              </FormControl>
              <FormControl required
                           error={!password.isValid}
                           fullWidth
                           aria-describedby="password-error-text">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input id="password" 
                       name="password" 
                       type="password"                       
                       onChange={this.handleChange} />
                {/* {isLoginFailure ? <FormHelperText id="password-error-text">Error</FormHelperText> : ""}                 */}
              </FormControl>
              <Button disabled={!(email.isValid && password.isValid)}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}>
                    Register
              </Button>              
            </form>
            <Typography className={classes.submit}>Already registered? </Typography><NavLink to="/login" >Login</NavLink>
          </Paper>
          <Typography className={classes.footer}>Сервис разработан при поддержке ФГБУ "Фонд содействия инновациям"</Typography>
      </main>
    );
  }
}

export default withStyles(styles)(RegisterForm);
