import uuidv4 from 'uuid/v4';
import { store } from '../store/configureStore';
import { updateKernelState } from '../actions/kernel';
import { templateExecuteMsg } from '../utils/jupyterTpls';

class ExecuteHanler {
  _operations = {};

  _buildMsg(source, msgId) {
    const msg = Object.assign(templateExecuteMsg, {});
    msg.content.code = source;
    msg.header.msg_id = msgId;
    return msg;
  }

  build({ source, notebookName, callback }) {
    const msgId = uuidv4();
    const data = this._buildMsg(source, msgId);

    this._operations[msgId] = callback;
    return { msg: data, notebookName };
  }

  handle(req) {
    let operationCallback;
    const { data } = req;
  
    switch (data.header.msgType) {
      // case 'execute_input': // start execute source
      case 'execute_reply': // execute output
        operationCallback = this._operations[data.parentHeader.msgId];

        if (operationCallback) {
          operationCallback({ executionCount: data.content.executionCount });
          delete this._operations[data.parentHeader.msgId];
        }
        break;

      case 'status': // change kernel status
        const executionState = data.content.executionState;

        store.dispatch(updateKernelState({ executionState }));
        break;

      case 'error':
      case 'stream': // stream stdout
      case 'execute_result':
      case 'display_data': // display graphics image/png
        operationCallback = this._operations[data.parentHeader.msgId];

        if (operationCallback) {
          operationCallback({
            ...data.content,
            outputType: data.header.msgType,
          });
        }
        break;

      default:
        break;
    }
  }
}

const executeHandler = new ExecuteHanler();

export default executeHandler;
