import React from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import Error from '@material-ui/icons/Error';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
});

class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      isLoad: false,
      isLoaded: false,
      isLoadedError: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  async uploadFile(file) {
    const { uploadRequest, onUploadSuccess } = this.props;
    const formData = new FormData();

    this.setState({ 
      ...this.state,
      file,
      isLoad: true,
    });

    formData.append('file', file);
    const response = await uploadRequest(formData);
    if (response.status < 400) {
      this.setState({
        ...this.state,
        isLoaded: true,
        isLoad: false,
      });
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } else {
      this.setState({
        ...this.state,
        isLoad: false,
        isLoadedError: true,
      });
    }
  }

  validate(file) {
    const { mimetypes, extensions, maxSize } = this.props;
    const ext = file.name.split('.')[file.name.split('.').length - 1];

    if (!mimetypes.includes(file.type)) {
      if (!extensions.includes(ext)) {
        alert(`Unsupported file format: ${file.type}`);
      } else {
        this.uploadFile(file);
      }
    } else if (file.size > maxSize) {
      alert(`Size error ${file.size} > ${maxSize}`);
    } else {
      this.uploadFile(file);
    }
  }

  onDrop(files) {
    if (files.length) {
      this.validate(files[0]);
    }
  }

  render() {
    const { classes, mimetypes } = this.props;
    const { file, isLoad, isLoaded, isLoadedError } = this.state;
    const types = mimetypes.join(', ');

    return (
      <section style={{border : 'black 1px dotted', cursor : 'pointer'}}>
        <Dropzone multiple={false}
                  onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p style={{textAlign : 'center'}}>Drop files here, or click to select files</p>
              <p style={{margin : "15px"}}>Types: {types}</p>
            </div>
          )}
        </Dropzone> 
        {file && 
          <List>
            <ListItem key={file.name}>
              <ListItemText>{file.name}</ListItemText>
              <Avatar>
                {isLoad && <CircularProgress className={classes.progress} />}
                {isLoaded && <Done color="secondary" />}
                {isLoadedError && <Error color="secondary" />}
              </Avatar>
            </ListItem>
          </List>
        }
      </section>
    );
  }
}

export default withStyles(styles)(FileUploader);

