import React, { Fragment } from 'react';
import {
  withStyles,
  Typography,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import NotebookMenuList from '../NotebookMenuList/NotebookMenuList';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { CONTAINER_RUNNING } from '../../constants/containers';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  attentionbutton: {
    marginBottom: theme.spacing.unit * 4,
    float: "right",
    padding: 0,
    marginTop: "4px",
  },

  header6: {
    display: "inline-block",
    marginBottom : '12px',
  },
  containerInfo : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'flex-end',
  }
});


class ServersMenuList extends React.Component {
    constructor(props) {
        super(props);
        this.handleFetchApplicationSpark = this.handleFetchApplicationSpark.bind(this);
        this.handlePostApplicationSpark = this.handlePostApplicationSpark.bind(this);
        this.handleChangeStatusApplicationSpark = this.handleChangeStatusApplicationSpark.bind(this);
    };
    handleFetchApplicationSpark() {
        const { fetchApplicationSpark } = this.props;
    
        fetchApplicationSpark();
    };
    handlePostApplicationSpark() {
        const { postApplicationSpark } = this.props;    
        postApplicationSpark();
    };

    handleChangeStatusApplicationSpark(action) {
        const { postApplicationSpark, changeStatusApplicationSpark, spark } = this.props;    
        return async () => {
            if (spark.master === null) {
              postApplicationSpark();
            } else {
              changeStatusApplicationSpark({action});
            }
            
        }
    };
    componentDidMount(){
        this.handleFetchApplicationSpark();
    }

  render() {
    const { classes } = this.props;
    const {
        application, 
        spark,
    } = this.props;
    

    
    return (
      <div className="">
        <Typography variant="h6" classes={{ root: classes.header6 }}>Servers</Typography>
        <br/>
        <Typography variant="subtitle1" classes={{ root: classes.header6 }}>Jupyter Notebook</Typography>
        {application &&
              <Fragment>                
                <div className={classes.containerInfo}>                  
                  <div style={{display : 'flex', justifyContent : 'flex-start', marginRight : 'auto'}}>
                  <FiberManualRecord style={{padding : '0', 
                                      display : 'inline-flex', 
                                      marginRight : 'auto',
                                      color : application.state === 'TASK_RUNNING' ? 'green' : 'red'
                                      }}/>
                  <Tooltip title='TASK_RUNNING - ready to execute code; TASK_KILLING/STARTING - container terminating/starting in progress; NOT RUNNING - server suspended' 
                           placement='right-end'>
                    <Typography style={{display : 'flex', marginRight : 'auto', marginLeft: '5px', alignSelf : 'center'}} 
                                variant="body1">
                                {application.state ? application.state : 'NOT RUNNING'}
                    </Typography>
                  </Tooltip>
                  </div>
                  <IconButton style={{padding : '0', display : 'inline-flex'}}
                                disabled={application.state !== CONTAINER_RUNNING}
                               onClick={this.props.handleChangeContainerState('stop')}
                              >
                      <StopIcon/>
                  </IconButton>
                  <IconButton style={{padding : '0', display : 'inline-flex'}}
                              disabled={application.state === CONTAINER_RUNNING} 
                              onClick={this.props.handleChangeContainerState('start')}
                              >
                      <PlayArrow/>
                  </IconButton>
                </div>
                <br/>
              </Fragment>
            }
                <br/>
                <Typography variant="subtitle1" classes={{ root: classes.header6 }}>Apache Spark</Typography>                
                <br/>
                {
                    spark && spark.master ?
                    <Fragment>                
                      <div className={classes.containerInfo}>                  
                        <div style={{display : 'flex', justifyContent : 'flex-start', marginRight : 'auto'}}>
                        <FiberManualRecord style={{padding : '0', 
                                            display : 'inline-flex', 
                                            marginRight : 'auto',
                                            color : spark.master.state === 'TASK_RUNNING' ? 'green' : 'red'
                                            }}/>
                        <Tooltip title='TASK_RUNNING - ready to execute code; TASK_KILLING/STARTING - container terminating/starting in progress; NOT RUNNING - server suspended' 
                                 placement='right-end'>
                          <Typography style={{display : 'flex', marginRight : 'auto', marginLeft: '5px', alignSelf : 'center'}} 
                                      variant="body1">
                                      {spark.master.state ? spark.master.state : 'NOT RUNNING'}
                          </Typography>
                        </Tooltip>
                        </div>
                        <IconButton style={{padding : '0', display : 'inline-flex'}}
                                disabled={spark.master.state !== CONTAINER_RUNNING}
                                onClick={this.handleChangeStatusApplicationSpark('stop')}
                              >
                        <StopIcon/>
                    </IconButton> 
                        <IconButton style={{padding : '0', display : 'inline-flex'}}
                                    disabled={spark.master.state === CONTAINER_RUNNING} 
                                    onClick={this.handleChangeStatusApplicationSpark('start')}
                                    >
                            <PlayArrow/>
                        </IconButton>
                      </div>                      
                      <br/>
                        <Tooltip title='Use this endpoint to connect to cluster in code' 
                                placement='bottom-start'>
                          <Typography style={{display : 'flex', marginRight : 'auto', marginLeft: '5px', alignSelf : 'center'}} 
                                      variant="body1">Endpoint: 
                                      {spark.master.endpoint ? spark.master.endpoint : 'N/A'}
                          </Typography>
                        </Tooltip>
                        <Tooltip title='You can change workers through Account' 
                                placement='bottom-start'>
                          <Typography style={{display : 'flex', marginRight : 'auto', marginLeft: '5px', alignSelf : 'center'}} 
                                      variant="body1">Workers: {spark.master.state === CONTAINER_RUNNING ? spark.worker.tasks.length : 0}
                          </Typography>
                        </Tooltip>
                        <br/>
                        <Button variant="contained"
                                size='small'
                                onClick={this.handleFetchApplicationSpark}>update status
                        </Button>
                    </Fragment>                    
                    :
                    <Button variant="contained"
                            onClick={this.handlePostApplicationSpark}>Start
                    </Button>
                }                
      </div>
    );
  }
}

export default withStyles(styles)(ServersMenuList);
