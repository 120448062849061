import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { 
  fetchApplication,
  changeStatusApplication,
} from '../actions/application';
import { 
  fetchNotebook,
  fetchNotebooks,
  createNotebook,
  renameNotebook,
  deleteNotebook,
} from '../actions/notebooks';
import Workspace from '../components/Workspace/Workspace';

const mapStateToProps = state => ({
  application: state.application.data,
  notebooks: state.notebooks.list,
  notebook: state.notebooks.active,
  datasets: state.notebooks.datasets,
  isServiceUnavailable: state.application.isServiceUnavailable,
  isFetchApplicationSuccess: state.application.isFetchApplicationSuccess,
  isChangeStatusApplicationRequest: state.application.isChangeStatusApplicationRequest, 
  isCreateNotebookRequest: state.notebooks.isCreateNotebookRequest,
  isRenameNotebookRequest: state.notebooks.isRenameNotebookRequest,
  isDeleteNotebookRequest: state.notebooks.isDeleteNotebookRequest,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  fetchApplication: () => dispatch(fetchApplication()),
  changeStatusApplication: args => dispatch(changeStatusApplication(args)),
  fetchNotebook: args => dispatch(fetchNotebook(args)),
  fetchNotebooks: args => dispatch(fetchNotebooks(args)),
  createNotebook: args => dispatch(createNotebook(args)),
  renameNotebook: args => dispatch(renameNotebook(args)),
  deleteNotebook: args => dispatch(deleteNotebook(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
