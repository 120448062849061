import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import DatasetMenuItem from '../DatasetMenuItem/DatasetMenuItem';

class DatasetList extends React.Component {
  render() {
    const { 
      datasets, 
    //   renameNotebook, 
    //   deleteNotebook, 
    //   handleOpenNotebook 
    } = this.props;

    return (
      <Scrollbars autoHeight
                  autoHeightMin={200}
                  autoHeightMax={500}>
                  {datasets.map(dataset => <div key={dataset.name}>
                  <DatasetMenuItem datasetName={dataset.name}/>
                  </div>)}
        
        <div>
          <div>

          </div>
        </div>
      </Scrollbars>
    )
  }
}

export default DatasetList;
