import requests from '../requests';
import { fetchNotebooks } from './notebooks';

export const FETCH_APPLICATION_REQUEST = 'FETCH_APPLICATION_REQUEST';
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_FAILURE = 'FETCH_APPLICATION_FAILURE';

function fetchApplicationRequest() {
  return {
    type: FETCH_APPLICATION_REQUEST
  };
}

function fetchApplicationSuccess(payload) {
  return {
    type: FETCH_APPLICATION_SUCCESS,
    payload,
  };
}

function fetchApplicationFailure(payload) {
  return {
    type: FETCH_APPLICATION_FAILURE,
    payload,
  };
}

export const fetchApplication = () => {
  return async dispatch => {
    dispatch(fetchApplicationRequest());
    const response = await requests.application.get();    
    if (response.status < 400) {
      dispatch(fetchApplicationSuccess(response));
      dispatch(fetchNotebooks());
    } else {
      if (response.status === 404) {
        const postResponse = await requests.application.post();
        if (postResponse.status < 400) {
          dispatch(fetchApplicationSuccess(postResponse));
          dispatch(fetchNotebooks());
        } else {
          dispatch(fetchApplicationFailure(postResponse));
        }
      } 
      dispatch(fetchApplicationFailure(response));
    }
  };
};

export const POST_APPLICATION_REQUEST = 'POST_APPLICATION_REQUEST';
export const POST_APPLICATION_SUCCESS = 'POST_APPLICATION_SUCCESS';
export const POST_APPLICATION_FAILURE = 'POST_APPLICATION_FAILURE';

function postApplicationRequest() {
  return {
    type: POST_APPLICATION_REQUEST
  };
}

function postApplicationSuccess(payload) {
  return {
    type: POST_APPLICATION_SUCCESS,
    payload,
  };
}

function postApplicationFailure(payload) {
  return {
    type: POST_APPLICATION_FAILURE,
    payload,
  };
}

export const postApplication = (args) => {
  return async dispatch => {
    dispatch(postApplicationRequest());
    const response = await requests.application.post(args);
    console.log('GET APPLICATION RESPONSE: ', response);
    if(response.status < 400) {
      dispatch(postApplicationSuccess(response));
      dispatch(fetchNotebooks());
    } else {
      dispatch(postApplicationFailure(response));
    }
  };
};

export const CHANGE_STATUS_APPLICATION_REQUEST = 'CHANGE_STATUS_APPLICATION_REQUEST';
export const CHANGE_STATUS_APPLICATION_SUCCESS = 'CHANGE_STATUS_APPLICATION_SUCCESS';
export const CHANGE_STATUS_APPLICATION_FAILURE = 'CHANGE_STATUS_APPLICATION_FAILURE';

function changeStatusApplicationRequest() {
  return {
    type: CHANGE_STATUS_APPLICATION_REQUEST
  };
}

function changeStatusApplicationSuccess(payload) {
  return {
    type: CHANGE_STATUS_APPLICATION_SUCCESS,
    payload,
  };
}

function changeStatusApplicationFailure(payload) {
  return {
    type: CHANGE_STATUS_APPLICATION_FAILURE,
    payload,
  };
}

export const changeStatusApplication = (args) => {
  return async dispatch => {
    dispatch(changeStatusApplicationRequest());
    const response = await requests.application.changeStatus(args);
    if(response.status < 400) {
      dispatch(changeStatusApplicationSuccess(response));
    } else {
      dispatch(changeStatusApplicationFailure(response));
    }
  };
};

export const UPDATE_APPLICATION_STATE = 'UPDATE_APPLICATION_STATE';

export const updateApplicationState = (payload) => ({
  type: UPDATE_APPLICATION_STATE,
  payload,
});


export const FETCH_APPLICATION_SPARK_REQUEST = 'FETCH_APPLICATION_SPARK_REQUEST';
export const FETCH_APPLICATION_SPARK_SUCCESS = 'FETCH_APPLICATION_SPARK_SUCCESS';
export const FETCH_APPLICATION_SPARK_FAILURE = 'FETCH_APPLICATION_SPARK_FAILURE';

function fetchApplicationSparkRequest() {
  return {
    type: FETCH_APPLICATION_SPARK_REQUEST
  };
}

function fetchApplicationSparkSuccess(payload) {
  return {
    type: FETCH_APPLICATION_SPARK_SUCCESS,
    payload,
  };
}

function fetchApplicationSparkFailure(payload) {
  return {
    type: FETCH_APPLICATION_SPARK_FAILURE,
    payload,
  };
}

export const fetchApplicationSpark = () => {
  return async dispatch => {
    dispatch(fetchApplicationSparkRequest());
    const response = await requests.application.getSpark();    
    if (response.status === 404 || response.status < 400) {
      dispatch(fetchApplicationSparkSuccess(response));
    } else {
      dispatch(fetchApplicationSparkFailure(response));
    }
  };
};

export const POST_APPLICATION_SPARK_REQUEST = 'POST_APPLICATION_SPARK_REQUEST';
export const POST_APPLICATION_SPARK_SUCCESS = 'POST_APPLICATION_SPARK_SUCCESS';
export const POST_APPLICATION_SPARK_FAILURE = 'POST_APPLICATION_SPARK_FAILURE';

function postApplicationSparkRequest() {
  return {
    type: POST_APPLICATION_SPARK_REQUEST
  };
}

function postApplicationSparkSuccess(payload) {
  return {
    type: POST_APPLICATION_SPARK_SUCCESS,
    payload,
  };
}

function postApplicationSparkFailure(payload) {
  return {
    type: POST_APPLICATION_SPARK_FAILURE,
    payload,
  };
}

export const postApplicationSpark = (args) => {
  return async dispatch => {
    dispatch(postApplicationSparkRequest());
    const response = await requests.application.postSpark(args);
    if(response.status < 400) {
      dispatch(postApplicationSparkSuccess(response));
    } else {
      dispatch(postApplicationSparkFailure(response));
    }
  };
};

export const CHANGE_STATUS_APPLICATION_SPARK_REQUEST = 'CHANGE_STATUS_APPLICATION_SPARK_REQUEST';
export const CHANGE_STATUS_APPLICATION_SPARK_SUCCESS = 'CHANGE_STATUS_APPLICATION_SPARK_SUCCESS';
export const CHANGE_STATUS_APPLICATION_SPARK_FAILURE = 'CHANGE_STATUS_APPLICATION_SPARK_FAILURE';

function changeStatusApplicationSparkRequest() {
  return {
    type: CHANGE_STATUS_APPLICATION_SPARK_REQUEST
  };
}

function changeStatusApplicationSparkSuccess(payload) {
  return {
    type: CHANGE_STATUS_APPLICATION_SPARK_SUCCESS,
    payload,
  };
}

function changeStatusApplicationSparkFailure(payload) {
  return {
    type: CHANGE_STATUS_APPLICATION_SPARK_FAILURE,
    payload,
  };
}

export const changeStatusApplicationSpark = (args) => {
  return async dispatch => {
    dispatch(changeStatusApplicationSparkRequest());
    const response = await requests.application.changeSparkStatus(args);
    if(response.status < 400) {
      dispatch(changeStatusApplicationSparkSuccess(response));
    } else {
      dispatch(changeStatusApplicationSparkFailure(response));
    }
  };
};

export const UPDATE_APPLICATION_SPARK_MASTER = 'UPDATE_APPLICATION_SPARK_MASTER';

export const updateApplicationSparkMaster = (payload) => ({
  type: UPDATE_APPLICATION_SPARK_MASTER,
  payload,
});

export const UPDATE_APPLICATION_SPARK_WORKER = 'UPDATE_APPLICATION_SPARK_WORKER';

export const updateApplicationSparkWorker = (payload) => ({
  type: UPDATE_APPLICATION_SPARK_WORKER,
  payload,
});


export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE';

export const serviceUnavailable = () => ({
  type: SERVICE_UNAVAILABLE,
});
