import { connect } from 'react-redux';
import { 
  fetchKernel, 
  restartKernel, 
  interruptKernel 
} from '../actions/kernel';
import Kernel from '../components/Kernel/Kernel';

const mapStateToProps = state => ({
  kernel: state.kernel.data,
  isFetchKernelRequest: state.kernel.isFetchKernelRequest,
  isFetchKernelSuccess: state.kernel.isFetchKernelSuccess,
  isFetchKernelFailure: state.kernel.isFetchKernelFailure,
  isRestartKernelRequest: state.kernel.isRestartKernelRequest,
  isRestartKernelSuccess: state.kernel.isRestartKernelSuccess,
  isRestartKernelFailure: state.kernel.isRestartKernelFailure,
  isInterruptKernelRequest: state.kernel.isInterruptKernelRequest,
  isInterruptKernelSuccess: state.kernel.isInterruptKernelSuccess,
  isInterruptKernelFailure: state.kernel.isInterruptKernelFailure,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchKernel: () => dispatch(fetchKernel({ notebookName: props.notebookName })),
  restartKernel: () => dispatch(restartKernel({ notebookName: props.notebookName })),
  interruptKernel: () => dispatch(interruptKernel({ notebookName: props.notebookName })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Kernel);
