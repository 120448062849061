import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from '../actions/user';
import { LOGOUT } from '../actions/auth';

const initialState = {
  data: null,
  isFetchUserRequest: false,
  isFetchUserSuccess: false,
  isFetchUserFailure: false,
  isResetPasswordRequest: false,
  isResetPasswordSuccess: false,
  isResetPasswordFailure: false,
  isChangePasswordRequest: false,
  isChangePasswordSuccess: false,
  isChangePasswordFailure: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetchUserRequest: true,
        isFetchUserSuccess: false,
        isFetchUserFailure: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetchUserRequest: false,
        isFetchUserSuccess: true,
        isFetchUserFailure: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetchUserRequest: true,
        isFetchUserSuccess: false,
        isFetchUserFailure: true,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordRequest: true,
        isResetPasswordSuccess: false,
        isResetPasswordFailure: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordRequest: false,
        isResetPasswordSuccess: true,
        isResetPasswordFailure: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetPasswordRequest: false,
        isResetPasswordSuccess: false,
        isResetPasswordFailure: true,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangePasswordRequest: true,
        isChangePasswordSuccess: false,
        isChangePasswordFailure: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordRequest: false,
        isChangePasswordSuccess: true,
        isChangePasswordFailure: false,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangePasswordRequest: false,
        isChangePasswordSuccess: false,
        isChangePasswordFailure: true,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
