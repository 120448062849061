import request from '../utils/request';
import { getStateUser } from '../utils/store';

export const get = async () => {
  const user = getStateUser();
  const url = `users/${user.id}`;
  
  return await request(url);
};

export const put = async (data) => {
  const user = getStateUser();
  const url = `users/${user.id}`;

  return await request(url, 'PUT', data);
};

export const resetPassword = async (email) => {
  const url = 'users/reset-password';
  
  return await request(url, 'POST', { email });
};
