import { connect } from 'react-redux';
import { login } from '../actions/auth';
import {resetPassword} from '../actions/user';
import LoginForm from '../components/LoginForm/LoginForm';

const mapStateToProps = state => ({ 
  isAuthenticated: state.auth.isAuthenticated,
  isLoginRequest: state.auth.isLoginRequest,
  isLoginSuccess: state.auth.isLoginSuccess,
  isLoginFailure: state.auth.isLoginFailure,
  isRegisterSuccess : state.auth.isRegisterSuccess,
  message : state.auth.message
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(login(email, password)),
  resetPassword : (email) => dispatch(resetPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
