import uuidv4 from 'uuid/v4';
import { 
  FETCH_NOTEBOOK_REQUEST,
  FETCH_NOTEBOOK_SUCCESS,
  FETCH_NOTEBOOK_FAILURE,
  FETCH_NOTEBOOKS_REQUEST,
  FETCH_NOTEBOOKS_SUCCESS,
  FETCH_NOTEBOOKS_FAILURE,
  CREATE_NOTEBOOK_REQUEST,
  CREATE_NOTEBOOK_SUCCESS,
  CREATE_NOTEBOOK_FAILURE,
  RENAME_NOTEBOOK_REQUEST,
  RENAME_NOTEBOOK_SUCCESS,
  RENAME_NOTEBOOK_FAILURE,
  UPDATE_NOTEBOOK_REQUEST,
  UPDATE_NOTEBOOK_SUCCESS,
  UPDATE_NOTEBOOK_FAILURE,
  DELETE_NOTEBOOK_REQUEST,
  DELETE_NOTEBOOK_SUCCESS,
  DELETE_NOTEBOOK_FAILURE,
} from '../actions/notebooks';
import {
  CREATE_CELL,
  UPDATE_CELL,
  DELETE_CELL,
} from '../actions/cells';
import { LOGOUT } from '../actions/auth';
import { notebookCell } from '../utils/jupyterTpls';

const initialState = {
  list: [],
  datasets: [],
  active: null,
  cells: [],
  isFetchNotebookRequest: false,
  isFetchNotebookSuccess: false,
  isFetchNotebookFailure: false,
  isFetchNotebooksRequest: false,
  isFetchNotebooksSuccess: false,
  isFetchNotebooksFailure: false,
  isCreateNotebookRequest: false,
  isCreateNotebookSuccess: false,
  isCreateNotebookFailure: false,
  isRenameNotebookRequest: false,
  isRenameNotebookSuccess: false,
  isRenameNotebookFailure: false,
  isUpdateNotebookRequest: false,
  isUpdateNotebookSuccess: false,
  isUpdateNotebookFailure: false,
  isDeleteNotebookRequest: false,
  isDeleteNotebookSuccess: false,
  isDeleteNotebookFailure: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTEBOOKS_REQUEST:
      return {
        ...state,
        isFetchNotebooksRequest: true,
        isFetchNotebooksSuccess: false,
        isFetchNotebooksFailure: false,
      };
    case FETCH_NOTEBOOKS_SUCCESS:
      return {
        ...state,
        list: action.payload.data ? action.payload.data.content.filter(item => item.name.endsWith('.ipynb')) : [],
        datasets: action.payload.data ? action.payload.data.content.filter(item => (
          item.name.endsWith('.csv') || item.name.endsWith('.json') || item.name.endsWith('.txt')
        )) : [],
        isFetchNotebooksRequest: false,
        isFetchNotebooksSuccess: true,
        isFetchNotebooksFailure: false,
      };
    case FETCH_NOTEBOOKS_FAILURE:
      return {
        ...state,
        isFetchNotebooksRequest: false,
        isFetchNotebooksSuccess: false,
        isFetchNotebooksFailure: true,
      };
    case FETCH_NOTEBOOK_REQUEST:
      return {
        ...state,
        isFetchNotebookRequest: true,
        isFetchNotebookSuccess: false,
        isFetchNotebookFailure: false,
      };
    case FETCH_NOTEBOOK_SUCCESS:
      const cells = action.payload.data.content.cells.map(cell => {
        if (!cell.__id) {
          cell.__id = uuidv4();
        }
        return cell;
      });
      const active = action.payload.data;
      active.content.cells = [];
      return {
        ...state,
        cells,
        active,
        isFetchNotebookRequest: false,
        isFetchNotebookSuccess: true,
        isFetchNotebookFailure: false,
      };
    case FETCH_NOTEBOOK_FAILURE:
      return {
        ...state,
        isFetchNotebookRequest: false,
        isFetchNotebookSuccess: false,
        isFetchNotebookFailure: true,
      };
    case CREATE_NOTEBOOK_REQUEST:
      return {
        ...state,
        isCreateNotebookRequest: true,
        isCreateNotebookSuccess: false,
        isCreateNotebookFailure: false,
      };
    case CREATE_NOTEBOOK_SUCCESS:
      return {
        ...state,
        list: state.list.concat([action.payload.data, ]),
        isCreateNotebookRequest: false,
        isCreateNotebookSuccess: true,
        isCreateNotebookFailure: false,
      };
    case CREATE_NOTEBOOK_FAILURE:
      return {
        ...state,
        isCreateNotebookRequest: false,
        isCreateNotebookSuccess: false,
        isCreateNotebookFailure: true,
      };
    case RENAME_NOTEBOOK_REQUEST:
      return {
        ...state,
        isRenameNotebookRequest: true,
        isRenameNotebookSuccess: false,
        isRenameNotebookFailure: false,
      };
    case RENAME_NOTEBOOK_SUCCESS:
      return {
        ...state,
        active: state.active && {
          ...state.active,
          name: action.payload.__args.name === state.active.name ? action.payload.data.name : state.active.name
        },
        list: state.list.map(notebook => {
          if (notebook.name === action.payload.__args.name) {
            return {
              ...notebook,
              name: action.payload.data.name,
            }
          } else {
            return notebook;
          }
        }),
        isRenameNotebookRequest: false,
        isRenameNotebookSuccess: true,
        isRenameNotebookFailure: false,
      };
    case RENAME_NOTEBOOK_FAILURE:
      return {
        ...state,
        isRenameNotebookRequest: false,
        isRenameNotebookSuccess: false,
        isRenameNotebookFailure: true,
      };
    case UPDATE_NOTEBOOK_REQUEST:
      return {
        ...state,
        isUpdateNotebookRequest: true,
        isUpdateNotebookSuccess: false,
        isUpdateNotebookFailure: false,
      };
    case UPDATE_NOTEBOOK_SUCCESS:
      return {
        ...state,
        isUpdateNotebookRequest: false,
        isUpdateNotebookSuccess: true,
        isUpdateNotebookFailure: false,
      };
    case UPDATE_NOTEBOOK_FAILURE:
      return {
        ...state,
        isUpdateNotebookRequest: false,
        isUpdateNotebookSuccess: false,
        isUpdateNotebookFailure: true,
      };
    case DELETE_NOTEBOOK_REQUEST:
      return {
        ...state,
        isDeleteNotebookRequest: true,
        isDeleteNotebookSuccess: false,
        isDeleteNotebookFailure: false,
      };
    case DELETE_NOTEBOOK_SUCCESS:
      return {
        ...state,
        active: action.payload.__args.name === state.active ? null : state.active,
        list: state.list.filter(notebook => notebook.name !== action.payload.__args.name),
        isDeleteNotebookRequest: false,
        isDeleteNotebookSuccess: true,
        isDeleteNotebookFailure: false,
      };
    case DELETE_NOTEBOOK_FAILURE:
      return {
        ...state,
        isDeleteNotebookRequest: false,
        isDeleteNotebookSuccess: false,
        isDeleteNotebookFailure: true,
      };
    case CREATE_CELL:
      const createCells = state.cells.slice();
      createCells.splice(action.payload.index || createCells.length, 0, { ...notebookCell, __id: uuidv4() });
      return {
        ...state,
        cells: createCells,
      };
    case UPDATE_CELL:
      const updateCells = state.cells.slice();
      updateCells[action.payload.index] = action.payload.data;
      return {
        ...state,
        cells: updateCells,
      };
    case DELETE_CELL:
      const deleteCells = state.cells.slice();
      deleteCells.splice(action.payload.index, 1);
      return {
        ...state,
        cells: deleteCells,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
