import { connect } from 'react-redux';
import { changePassword } from '../actions/user';
import Account from '../components/Account/Account';

const mapStateToProps = state => ({
  userId: state.auth.userId,
  user: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  changePassword: (data) => dispatch(changePassword(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account);
