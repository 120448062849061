import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import {NavLink} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';

// import PasswordReset from '../PasswordReset/PasswordReset';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField, Dialog, Slide } from '@material-ui/core';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  main: {
    width: 'auto',
    display: 'flex', // Fix IE 11 issue.
    flexDirection : 'column',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },  
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    height : 'fit-content',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  footer : {
    display : 'flex',
    marginTop : 'auto',
    textAlign : 'center',
  },
  error : {
    textAlign : 'center',
    background : theme.palette.secondary.main,
    color : 'white',
    width : '100%',
    marginBottom : '15px',
    padding : '5px',
  },
});

function Transition(props) {
  return <Slide direction='up' {...props} />;
};
export class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: {
        value: '',
        isValid: false,
        isValidated: false
      },
      password: {
        value: '',
        isValid: false,
        isValidated: false
      },
      resetEmail : '',
      resetIsOpen : false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenResetDialog = this.handleOpenResetDialog.bind(this);
    this.handleCloseResetDialog = this.handleCloseResetDialog.bind(this);
    this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  validateField(name, value) {
    let isValid = this.state[name].isValid;
    let isValidated = this.state[name].isValidated;

    switch (name) {
      case 'username':
        isValid = !!value.length;
        isValidated = true;
        break;
      case 'password':
        isValid = !!value.length;
        isValidated = true;
        break;

      default:
        break;
    };

    this.setState({ 
      ...this.state,
      [name]: {
        ...this.state[name],
        isValid,
        isValidated,
      }
    });
  } 

  handleChange(e) {
    const { name, value } = e.target;
    const state = Object.assign({}, this.state);

    state[name].value = value;

    this.setState(state, () => this.validateField(name, value));
  }

  handleOpenResetDialog(){
    this.setState({
      resetIsOpen : true
    })
  }

  handleCloseResetDialog(){
    this.setState({
      resetIsOpen : false
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    const { login } = this.props;
    const { username, password } = this.state;

    login(username.value, password.value);
  }

  handleSubmitResetPassword(e){

    const {resetPassword} = this.props;
    const {resetEmail} = this.state;

    resetPassword(resetEmail);
    this.handleCloseResetDialog();
  }

  handleChangeEmail(e){
    this.setState({
      resetEmail : e.target.value,
    })
  }


  render() {
    const { username, password, resetIsOpen } = this.state;
    const { isAuthenticated, isLoginFailure, isRegisterSuccess, classes, message } = this.props;

    if(isAuthenticated) return <Redirect to="/" />

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
        {
          message ? <Typography className={classes.error}>{message}</Typography> : ""
        }
          <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
          <Typography component="h1" variant="h5">Login</Typography>
            <form onSubmit={this.handleSubmit} className={classes.form}>
              <FormControl  required
                            error={!username.isValid}
                            fullWidth
                            margin="normal"                            
                            aria-describedby="username-error-text">
                <InputLabel htmlFor="username">E-mail</InputLabel>
                <Input id="username" 
                       name="username" 
                       autoFocus
                       autoComplete="email"
                       onChange={this.handleChange} />
                {/* {isLoginFailure ? <FormHelperText id="username-error-text">Error</FormHelperText> : ""}                 */}
              </FormControl>
              <FormControl required
                           error={!password.isValid}
                           fullWidth
                           aria-describedby="password-error-text">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input id="password" 
                       name="password" 
                       type="password"                       
                       onChange={this.handleChange} />
                {/* {isLoginFailure ? <FormHelperText id="password-error-text">Error</FormHelperText> : ""}                 */}
              </FormControl>
              <Button disabled={!(username.isValid && password.isValid)}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}>
                    Login
              </Button>
            </form>
            {
              isRegisterSuccess ?
              <Typography className={classes.submit}>
                You`re already registered. Dont forget to confirm e-mail
              </Typography>
              :
                <div style={{display : 'flex'}}>
                  <Typography className={classes.submit}>Not registered?</Typography>
                  <NavLink to="/register"
                           style={{marginTop : '24px', marginLeft : '10px'}}>Sign Up</NavLink>
                </div>
            }
            
            <div style={{display : 'flex', flexDirection : 'row'}}>
              <Typography className={classes.submit}
                          style={{alignSelf: 'center'}}>Forgot password?</Typography>
              <Button variant="text" 
                      style={{alignSelf : 'center', marginTop : '24px'}}
                      onClick={this.handleOpenResetDialog}>
                Reset Password
              </Button>              
            </div>
          </Paper>
          <Typography className={classes.footer}>Сервис разработан при поддержке ФГБУ "Фонд содействия инновациям"</Typography>
          <Dialog maxWidth='md'
                    fullWidth
                    open={resetIsOpen}
                    onClose={this.handleCloseResetDialog}
                    TransitionComponent={Transition}>
                    <DialogTitle id="max-width-dialog-title">Reset Password</DialogTitle>
                    <DialogContent className={classes.dialog}>                    
                    <DialogContentText>
                            Please, submit account e-mail address
                    </DialogContentText>
                    <div className={classes.root}>
                      <TextField label='E-mail Address'
                                  onChange={this.handleChangeEmail}
                                  autoFocus
                                  className={classes.textField}
                                  type='email'
                                  margin='normal'
                                  fullWidth
                                  variant='standard' />
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleSubmitResetPassword} color="primary" >
                        Reset password
                      </Button>
                    </DialogActions>
                </Dialog>
      </main>
    );
  }
}


export default withStyles(styles)(LoginForm);