import {combineReducers} from 'redux';
import auth from './auth';
import user from './user';
import application from './application';
import notebooks from './notebooks';
import kernel from './kernel';

export default combineReducers({
  auth, 
  user, 
  application, 
  notebooks,
  kernel,
});
