import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';
import Autorenew from '@material-ui/icons/Autorenew';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import HourglassFull from '@material-ui/icons/HourglassFull';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

class Kernel extends Component {
  render() {
    const { 
      kernel,
      isFetchKernelRequest,
      isRestartKernelRequest,
      isInterruptKernelRequest,
      fetchKernel,
      notebookName,
      restartKernel,
      interruptKernel,
    } = this.props;

    return (
      kernel 
        ? <div style={{margin : 'auto', display : 'flex', alignItems : 'center'}}>
            <Tooltip title={kernel.executionState}
                     placement='bottom'>
            {
              kernel.executionState === 'busy' ? <HourglassFull style={{fontSize : '16px'}} color='secondary'/> : <HourglassEmpty style={{fontSize : '16px'}}/> 
            }
            </Tooltip>
            
            <Typography style={{marginRight : '15px'}}> {notebookName ? notebookName : ''} </Typography>
            <IconButton onClick={interruptKernel}
                    color="primary"                    
                    style={{margin : 'auto', padding : '0'}}
                    title="Interrupt kernel"
                    aria-label="Interrupt kernel">
              <StopIcon />
            </IconButton>
            <IconButton onClick={restartKernel}
                        color="primary"                    
                        style={{margin : 'auto', padding : '0'}}
                        title="Restart kernel"
                        aria-label="Restart kernel">
              <Autorenew />
            </IconButton>
          </div>
        : ''
    );
  }
}

export default Kernel;
