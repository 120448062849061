import jwtDecode from 'jwt-decode';
import requests from '../requests';
import ws from '../ws';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

function loginRequest() {
  return {type: LOGIN_REQUEST};
}

function loginSucces(payload) {
  return {type: LOGIN_SUCCESS, payload};
}

function loginFailure(payload) {
  return {type: LOGIN_FAILURE, payload}
}

export const login = (email, password) => {
  return async dispatch => {
    dispatch(loginRequest());
    const response = await requests.auth.login(email, password);
    if (response.status < 400) {
      // Setting user id from jwt decode to response
      const tokenData = jwtDecode(response.data.token);
      response.data.id = tokenData.id;
      dispatch(loginSucces(response));
      // Setting auth data to LocalStorage
      localStorage.setItem('auth', JSON.stringify(response.data));
    } else {      
      dispatch(loginFailure(response));
    }
  };
};

export const LOGOUT = 'LOGOUT';

export function logout() {
  try {
    localStorage.removeItem('auth');
    ws.stop();
  } catch (err) {
    console.error(err);
  }
  return { type: LOGOUT };
}

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

function registerRequest() {
  return { type: REGISTER_REQUEST };
}

function registerSucces(payload) {
  return { type: REGISTER_SUCCESS, payload };
}

function registerFailure(payload) {
  return { type: REGISTER_FAILURE, payload };
}

export const register = (email, password) => {
  return async dispatch => {
    dispatch(registerRequest());
    const response = await requests.auth.register(email, password);
    if (response.status < 400) {
      dispatch(registerSucces(response));
    } else {
      dispatch(registerFailure(response));
    }
  };
};

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

function refreshTokenRequest() {
  return { type: REFRESH_TOKEN_REQUEST };
}

function refreshTokenSucces(payload) {
  return { type: REFRESH_TOKEN_SUCCESS, payload };
}

function refreshTokenFailure(payload) {
  return { type: REFRESH_TOKEN_FAILURE, payload };
}

export const refreshToken = (token) => {
  return async dispatch => {
    dispatch(refreshTokenRequest());
    // TODO
    const response = {};
    if (response.status < 400) {
      dispatch(refreshTokenSucces(response));
    } else {
      dispatch(refreshTokenFailure(response));
    }
  };
};
