import './styles/index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import fontawesome from '@fortawesome/fontawesome';
import fontawesomeBrands from '@fortawesome/fontawesome-free-brands';
import fontawesomeRegular from '@fortawesome/fontawesome-free-regular';
import fontawesomeSolid from '@fortawesome/fontawesome-free-solid';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Home from './containers/Home';
import Login from './containers/Login';
import Welcome from './components/Preferences/Preferences';
import Register from './containers/Register';
import PasswordReset from './components/PasswordReset/PasswordReset'
import PrivateRoute from './containers/PrivateRoute';
import { store, history } from './store/configureStore';

fontawesome.library.add(fontawesomeBrands, fontawesomeRegular, fontawesomeSolid);

window.store = store;

const theme = createMuiTheme({
  palette: {
    typography: {
      useNextVariants: true,
    },
    type: "light",
    textColor: "red",
    primary: {
      light: '#fff',
      main: '#7eb6e6',
      dark: '#000'
    },
    secondary: {
      main: '#f44336',
    },
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/welcome/" component={Welcome} />
          <Route exact path="/login/" component={Login} />
          <Route exact path="/register/" component={Register} />
          <Route exact path="/reset-password/" component={PasswordReset} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>, document.getElementById('root'));
