import createHistory from 'history/createBrowserHistory';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../reducers';

const initialState = {
    
};

export const history = createHistory();

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(routerMiddleware(history), thunkMiddleware, logger)
);
