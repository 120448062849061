import { store } from '../store/configureStore';
import { fetchNotebooks, fetchNotebooksSuccess } from '../actions/notebooks';
import { 
  updateApplicationState, 
  updateApplicationSparkMaster, 
  updateApplicationSparkWorker 
} from '../actions/application';
import { CONTAINER_RUNNING, CONTAINER_KILLING } from '../constants/containers';

class ApplicationHanler {
  handle(req) {
    const { data } = req;

    if (req.action === 'application') {
      if (data.state === CONTAINER_RUNNING) {
        store.dispatch(fetchNotebooks());
      }
      else if (data.state === CONTAINER_KILLING) {
        store.dispatch(fetchNotebooksSuccess({ data: null }));
      }
      
      store.dispatch(updateApplicationState(data));
    }
    else if (req.action === 'application-spark-master') {
      store.dispatch(updateApplicationSparkMaster(data));
    }
    else if (req.action === 'application-spark-worker') {
      store.dispatch(updateApplicationSparkWorker(data));
    }
  }
}

const applicationHandler = new ApplicationHanler();

export default applicationHandler;
