export const CREATE_CELL = 'CREATE_CELL';

export function createCell(payload) {
  return {
    type: CREATE_CELL,
    payload,
  }
}

export const UPDATE_CELL = 'UPDATE_CELL';

export function updateCell(payload) {
  return {
    type: UPDATE_CELL,
    payload,
  };
}

export const DELETE_CELL = 'DELETE_CELL';

export function deleteCell(payload) {
  return {
    type: DELETE_CELL,
    payload,
  };
}
