import ReconnectingWebSocket from 'reconnecting-websocket';
import * as config from '../config.json';
import { objectKeysToCamelCase } from '../utils/object';
import executeHandler from './executeHandler';
import applicationHandler from './applicationHandler';

class Ws {
  constructor() {
    this.url = `ws${config.apiProtocol === 'https' ? 's' : ''}://${config.apiHost}`;
    this.isStarted = false;
    this.isReady = false;
    this._ws = null;

    this._onMessage = this._onMessage.bind(this);
    this._onOpen = this._onOpen.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onError = this._onError.bind(this);
  }

  start(token) {
    if (!this.isStarted) {
      this._createWs(token);
    }
  }

  stop() {
    if (this.isStarted) {
      this._ws.close();
    }
  }

  send(action, data) {
    if (this._ws && this.isReady) {
      switch (action) {
        case 'execute':
          data = executeHandler.build(data);
          break;
        default:
          break;
      }

      const req = { action, data };
      console.log('WS SEND REQ: ', req);

      // Send request to server
      this._ws.send(JSON.stringify(req));
      return true;
    } else {
      console.error('WS NOT STARTED', action, data);
      return false;
    }
  }

  _createWs(token) {
    this._ws = new ReconnectingWebSocket(`${this.url}/?t=${token}`, [], { maxRetries: 5, connectionTimeout: 3000 });
    this._ws.binaryType = 'arraybuffer';
    this._ws.onmessage = this._onMessage;
    this._ws.onopen = this._onOpen;
    this._ws.onclose = this._onClose;
    this._ws.onerror = this._onError;
    this.isStarted = true;
  }

  _onOpen(e) {
    this.isReady = true;
    console.log('WS OPENED: ', e);
  }

  _onMessage(e) {
    try {
      const req = objectKeysToCamelCase(JSON.parse(e.data));
      console.log('WS REQUEST: ', req);

      if (!req.status || req.status < 400) {
        switch (req.action) {
          case 'execute':
            return executeHandler.handle(req);
          case 'application':
          case 'application-spark-master':
          case 'application-spark-worker':
            return applicationHandler.handle(req);
          default:
            break;
        }
      } else {
        console.log('WS MESSAGE UNKNOWN: ', e);
      }
    } catch (err) {
      console.log('WS MESSAGE ERR: ', err);
    }
  }

  _onClose(e) {
    this.isReady = false;
    this.isStarted = false;
    console.log('WS CLOSE: ', e);
  }

  _onError(e) {
    this.isReady = false;
    this.isStarted = false;
    console.log('WS ERR: ', e);
  }
}

const ws = new Ws();

export default ws;
