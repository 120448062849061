import { connect } from 'react-redux';
import NotebookActive from '../components/NotebookActive/NotebooksActive';
import {
  createCell,
  updateCell,
  deleteCell,
} from '../actions/cells';
import { 
  updateNotebook,
} from '../actions/notebooks';

const mapStateToProps = state => ({
  notebook: state.notebooks.active,
  cells: state.notebooks.cells,
  isUpdateNotebookRequest: state.notebooks.isUpdateNotebookRequest,
});

const mapDispatchToProps = dispatch => ({
  updateNotebook: args => dispatch(updateNotebook(args)),
  createCell: args => dispatch(createCell(args)),
  updateCell: args => dispatch(updateCell(args)),
  deleteCell: args => dispatch(deleteCell(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotebookActive);
