import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import NotebookMenuItem from '../NotebookMenuItem/NotebookMenuItem';

class NotebookList extends React.Component {
  render() {
    const { 
      notebooks, 
      renameNotebook, 
      deleteNotebook, 
      handleOpenNotebook 
    } = this.props;

    return (
      <Scrollbars autoHeight
                  autoHeightMin={200}
                  autoHeightMax={500}>
        {notebooks.map(item => (
          item.name.split('.').pop() === 'ipynb'
            ? <NotebookMenuItem key={`${item.name}_nb`}
                                notebookName={item.name}
                                renameNotebook={renameNotebook}
                                deleteNotebook={deleteNotebook}
                                handleOpenNotebook={handleOpenNotebook} />
            : ""
        ))}
        <div>
          <div>

          </div>
        </div>
      </Scrollbars>
    )
  }
}

export default NotebookList;
