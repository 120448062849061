import React from 'react';
import brace from 'brace';
import ReactMarkdown from 'react-markdown/with-html';
import 'brace/mode/python';
import 'brace/mode/markdown';
import 'brace/theme/textmate';
import 'brace/snippets/python';
import 'brace/ext/language_tools';
import AceEditor from 'react-ace';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import red from '@material-ui/core/colors/red';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ws from '../../ws';
import CellHeader from './CellHeader';
import CellOutputs from './CellOutputs';

const styles = theme => ({
  card: {
    marginBottom: "10px"
    // maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cellheader: {
    inlineSize: "fit-content",
    float: "right",
  },
  button: {
    padding: 0,
  },
});

class Cell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false,
      isSourceUpdated: false,
      execution : false,
      source: props.cell.source || '',
      expanded : true,
    };

    this.updateSourceTimeoutId = null;

    this.handleChangeCellType = this.handleChangeCellType.bind(this);
    this.handleExecuteResult = this.handleExecuteResult.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
    this.handleMouseEnter = this.mouseEnter.bind(this);
    this.handleMouseLeave = this.mouseLeave.bind(this);
    this.handleExecute = this.handleExecute.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  handleExpandClick() {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  updateCell(data) {
    const { cellId, updateCell } = this.props;

    updateCell({ index: cellId, data });
  }

  handleKeyPressed(e) {
    if (e.keyCode === 13 && e.ctrlKey) {
      this.handleExecute();
    }
  }

  handleExecute() {
    const { source } = this.state;
    const { notebookName, cell, sessionId } = this.props;

    ws.send('execute', { source, notebookName, sessionId, callback: this.handleExecuteResult });
    this.updateCell({ ...cell, source, outputs: [] });

    this.setState({
      execution : true
    });
  }

  handleExecuteResult(content) {
    const { cell } = this.props;

    if (typeof(content.executionCount) === 'number') {
      this.updateCell({ ...cell, executionCount: content.executionCount });
    }

    if (content.data || content.text || content.outputType === 'error') {
      this.updateCell({ ...cell, outputs: [content,] });
      this.setState({
        execution : false
      });
    }
  }

  handleChangeCode(source) {
    this.setState({
      ...this.state,
      source,
    });

    if (this.updateSourceTimeoutId) {
      clearTimeout(this.updateSourceTimeoutId);
    }

    this.updateSourceTimeoutId = setTimeout(() => {
      const source = this.state.source;
      const cell = this.props.cell;

      this.updateCell({ ...cell, source });
    }, 2000);
  }

  handleChangeCellType(cellType) {
    return () => {
      const cell = Object.assign(this.props.cell, {});

      cell.cellType = cellType;
      if(cellType === 'code') {
        cell.outputs = [];
        cell.executionCount = null;
      }
      this.updateCell(cell);
      
    };
  }

  mouseEnter() {
    this.setState({
      isHover: true,
    });
  }

  mouseLeave() {
    this.setState({
      isHover: false,
    });
  }

  render() {
    const { isHover, source } = this.state;
    const { 
      classes,
      cell, 
      cellId,
      createCell, 
      deleteCell, 
    } = this.props;
    
    return (
      <div className="cell" onKeyDown={this.handleKeyPressed}>
        <div className="box-cell header">
          <Card className={classes.card}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}>
            <div style={{ height: "34px" }}>
              {isHover ? 
                <CellHeader cellId={cellId}
                            cellType={cell.cellType}
                            executionCount={cell.executionCount}
                            isHover={isHover}
                            handleExecute={this.handleExecute}
                            handleChangeCellTypeCode={this.handleChangeCellType('code')}
                            handleChangeCellTypeMarkdown={this.handleChangeCellType('markdown')}
                            handleCreateCell={createCell}
                            handleDeleteCell={deleteCell} />
                 : "" 
              }
            </div>
            {/* {execution ? <LinearProgress /> : ''} */}
            
            <AceEditor id={cellId}
                       mode={cell.cellType === 'code' ? 'python' : cell.cellType}
                       value={source}
                       onChange={this.handleChangeCode}
                       name="idx"
                       width="100%"
                       theme="textmate"
                       showPrintMargin={true}
                       highlightActiveLine={true}
                       fontSize={18}
                       editorProps={{
                         $blockScrolling: Infinity
                       }}
                       setOptions={{
                         enableBasicAutocompletion: true,
                         enableLiveAutocompletion: true,
                         enableSnippets: true,
                         showLineNumbers: true,
                         maxLines: Infinity,
                         tabSize: 2
                       }} />
           
            <CardActions className={classes.actions} disableActionSpacing>
          {/* <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="Share">
            <ShareIcon />
          </IconButton> */}
          <IconButton
            className={classnames(classes.expand, classes.button, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon/>
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
          {cell.cellType === 'code' 
                ? <CellOutputs cellId={cellId} outputs={cell.outputs} />
                : <ReactMarkdown source={cell.source} 
                                  escapeHtml={false} 
                                  renderers={{
                                    listItem : (props) => {
                                      return <li style={{color : 'black', textTransform : 'none'}}>{props.children}</li>
                                    },
                                    heading : (props) => { 
                                      switch(props.level) {
                                        case 1:
                                          return <h1 style={{color : 'black', textTransform : 'none'}}>{props.children}</h1>                                        
                                        case 2:
                                          return <h2 style={{color : 'black', textTransform : 'none'}}>{props.children}</h2>
                                        case 3:
                                          return <h3 style={{color : 'black', textTransform : 'none'}}>{props.children}</h3>
                                        case 4:
                                          return <h4 style={{color : 'black', textTransform : 'none'}}>{props.children}</h4>
                                        case 5:
                                          return <h5 style={{color : 'black', textTransform : 'none'}}>{props.children}</h5>
                                        case 6:
                                          return <h6 style={{color : 'black', textTransform : 'none'}}>{props.children}</h6>
                                        default:
                                          return <h6 style={{color : 'black', textTransform : 'none'}}>{props.children}</h6>
                                      }                                    
                                    }
                                }}/>
              }
          </CardContent>
        </Collapse>
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Cell);
