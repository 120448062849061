import React from 'react';
import { TextField, IconButton, Dialog, Button, Slide, withStyles, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },

    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    modal: {
        flexDirection: 'column'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        
    }

});

function Transition(props) {
    return <Slide direction='up' {...props} />;
};

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.resetIsOpen,
            email : ''            
        }

        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value,
        });
    };       

    handleChangePassword() {
        const { changePassword } = this.props;
        const {oldpassword, newpassword, repeatpassword} = this.state;

        if (newpassword === repeatpassword) {
            changePassword({ password: oldpassword, newPassword: newpassword });
        } else {
            this.setState({
                passwordDidntMatch : true
            })
        }
    }

    handleClose() {
        this.setState({
            open : false
        })
    }

    render() {
        const { classes} = this.props;
        const { open } = this.state;

        
        return (
            <div>
                <Dialog maxWidth='md'
                    fullWidth
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}>
                    <DialogTitle id="max-width-dialog-title">Reset Password</DialogTitle>
                    <DialogContent className={classes.dialog}>                    
                    <DialogContentText>
                            Please, enter account e-mail
                        </DialogContentText>
                    <div className={classes.root}>
                        <TextField label='E-mail'
                                    autoFocus
                                   className={classes.textField}                         
                                   margin='normal'
                                   variant='standard' />                            
                        
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" >
                            Reset password
                    </Button>
                    </DialogActions>
                </Dialog>


            </div>
        )
    }
}

export default withStyles(styles)(PasswordReset);