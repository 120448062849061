import { LOGOUT } from '../actions/auth';
import {
  UPDATE_KERNEL_STATE,
  FETCH_KERNEL_REQUEST,
  FETCH_KERNEL_SUCCESS,
  FETCH_KERNEL_FAILURE,
  RESTART_KERNEL_REQUEST,
  RESTART_KERNEL_SUCCESS,
  RESTART_KERNEL_FAILURE,
  INTERRUPT_KERNEL_REQUEST,
  INTERRUPT_KERNEL_SUCCESS,
  INTERRUPT_KERNEL_FAILURE,
} from '../actions/kernel';

const initialState = {
  data: null,
  isFetchKernelRequest: false,
  isFetchKernelSuccess: false,
  isFetchKernelFailure: false,
  isRestartKernelRequest: false,
  isRestartKernelSuccess: false,
  isRestartKernelFailure: false,
  isInterruptKernelRequest: false,
  isInterruptKernelSuccess: false,
  isInterruptKernelFailure: false,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case UPDATE_KERNEL_STATE:
      return {
        ...state,
        data: state.data ? { ...state.data, ...action.payload } : action.payload,
      };
    case FETCH_KERNEL_REQUEST:
      return {
        ...state,
        isFetchKernelRequest: true,
        isFetchKernelSuccess: false,
        isFetchKernelFailure: false,
      };
    case FETCH_KERNEL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetchKernelRequest: true,
        isFetchKernelSuccess: false,
        isFetchKernelFailure: false,
      };
    case FETCH_KERNEL_FAILURE:
      return {
        ...state,
        isFetchKernelRequest: true,
        isFetchKernelSuccess: false,
        isFetchKernelFailure: false,
      };
    case RESTART_KERNEL_REQUEST:
      return {
        ...state,
        isRestartKernelRequest: true,
        isRestartKernelSuccess: false,
        isRestartKernelFailure: false,
      };
    case RESTART_KERNEL_SUCCESS:
      return {
        ...state,
        isRestartKernelRequest: false,
        isRestartKernelSuccess: true,
        isRestartKernelFailure: false,
      };
    case RESTART_KERNEL_FAILURE:
      return {
        ...state,
        isRestartKernelRequest: false,
        isRestartKernelSuccess: false,
        isRestartKernelFailure: true,
      };
    case INTERRUPT_KERNEL_REQUEST:
      return {
        ...state,
        isInterruptKernelRequest: true,
        isInterruptKernelSuccess: false,
        isInterruptKernelFailure: false,
      };
    case INTERRUPT_KERNEL_SUCCESS:
      return {
        ...state,
        isInterruptKernelRequest: false,
        isInterruptKernelSuccess: true,
        isInterruptKernelFailure: false,
      };
    case INTERRUPT_KERNEL_FAILURE:
      return {
        ...state,
        isInterruptKernelRequest: false,
        isInterruptKernelSuccess: false,
        isInterruptKernelFailure: true,
      };
    case LOGOUT:
      return initialState;
    default: 
      return state;
  }
};
