import request from '../utils/request';
import { getStateUser } from '../utils/store';

export const get = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/application`;
  return await request(url);
};

export const post = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/application`;
  return await request(url, 'POST', {});
};

export const changeStatus = async ({ action }) => {
  const user = getStateUser();
  const url = `users/${user.id}/application/${action}`;
  return await request(url);
};

export const getSpark = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/application/spark/`;
  return await request(url);
};

export const postSpark = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/application/spark`;
  return await request(url, 'POST', {});
};

export const changeSparkStatus = async ({ action }) => {
  const user = getStateUser();
  const url = `users/${user.id}/application/spark/${action}`;
  return await request(url);
}
