export const notebookCell = {
  cellType: 'code',
  executionCount: null,
  metadata: {
    trusted: true
  },
  outputs: [],
  source: ''
};

export const notebookContent = {
  "cells": [notebookCell, ],
  "metadata": {
  "kernelspec": {
    "display_name": "Python 3",
    "language": "python",
    "name": "python3"
  },
  "language_info": {
    "codemirror_mode": {
    "name": "ipython",
    "version": 3
    },
    "file_extension": ".py",
    "mimetype": "text/x-python",
    "name": "python",
    "nbconvert_exporter": "python",
    "pygments_lexer": "ipython3",
    "version": "3.5.2"
  }
  },
  "nbformat": 4,
  "nbformat_minor": 2
};

export const templateExecuteMsg = {
  channel: 'shell',
  header: {
    username: '',
    session: '',
    msg_id: '',
    version: '5.2',
    msg_type: 'execute_request'
  },
  parent_header: {},
  content: {
    code: '',
    silent: false,
    allow_stdin: false,
    store_history: true,
    stop_on_error: true,
    user_expressions: {},
  },
  metadata: {},
  buffers: [],
};
