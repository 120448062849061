import requests from '../requests'
import { fetchKernel } from './kernel';
import ws from '../ws';

export const FETCH_NOTEBOOKS_REQUEST = 'FETCH_NOTEBOOKS_REQUEST';
export const FETCH_NOTEBOOKS_SUCCESS = 'FETCH_NOTEBOOKS_SUCCESS';
export const FETCH_NOTEBOOKS_FAILURE = 'FETCH_NOTEBOOKS_FAILURE';

function fetchNotebooksRequest() {
  return {
    type: FETCH_NOTEBOOKS_REQUEST
  };
}

export function fetchNotebooksSuccess(payload) {
  return {
    type: FETCH_NOTEBOOKS_SUCCESS,
    payload,
  };
}

function fetchNotebooksFailure(payload) {
  return {
    type: FETCH_NOTEBOOKS_FAILURE,
    payload,
  };
}

export const fetchNotebooks = args => {
  return async dispatch => {
    dispatch(fetchNotebooksRequest());
    const response = await requests.notebooks.fetchNotebooks(args);

    response.__args = args;
    if(response.status < 400) {
      dispatch(fetchNotebooksSuccess(response));
    } else {
      dispatch(fetchNotebooksFailure(response));
    }
  }
};

export const FETCH_NOTEBOOK_REQUEST = 'FETCH_NOTEBOOK_REQUEST';
export const FETCH_NOTEBOOK_SUCCESS = 'FETCH_NOTEBOOK_SUCCESS';
export const FETCH_NOTEBOOK_FAILURE = 'FETCH_NOTEBOOK_FAILURE';

function fetchNotebookRequest() {
  return {
    type: FETCH_NOTEBOOK_REQUEST
  };
}

function fetchNotebookSuccess(payload) {
  return {
    type: FETCH_NOTEBOOK_SUCCESS,
    payload,
  };
}

function fetchNotebookFailure(payload) {
  return {
    type: FETCH_NOTEBOOK_FAILURE,
    payload,
  };
}

export const fetchNotebook = args => {
  return async dispatch => {
    dispatch(fetchNotebookRequest());
    const response = await requests.notebooks.fetchNotebook(args);

    response.__args = args;
    if(response.status < 400) {
      const { name } = args;

      dispatch(fetchNotebookSuccess(response));
      dispatch(fetchKernel({ notebookName: name }));
      ws.send('changeNotebook', { notebookName: name });
    } else {
      dispatch(fetchNotebookFailure(response));
    }
  }
};

export const CREATE_NOTEBOOK_REQUEST = 'CREATE_NOTEBOOK_REQUEST';
export const CREATE_NOTEBOOK_SUCCESS = 'CREATE_NOTEBOOK_SUCCESS';
export const CREATE_NOTEBOOK_FAILURE = 'CREATE_NOTEBOOK_FAILURE';

function createNotebookRequest() {
  return {
    type: CREATE_NOTEBOOK_REQUEST
  };
}

function createNotebookSuccess(payload) {
  return {
    type: CREATE_NOTEBOOK_SUCCESS,
    payload,
  };
}

function createNotebookFailure(payload) {
  return {
    type: CREATE_NOTEBOOK_FAILURE,
    payload,
  };
}

export const createNotebook = args => {
  return async dispatch => {
    dispatch(createNotebookRequest());
    const response = await requests.notebooks.createNotebook(args);

    response.__args = args;
    if(response.status < 400) {
      dispatch(createNotebookSuccess(response));
    } else {
      dispatch(createNotebookFailure(response));
    }
  }
};

export const RENAME_NOTEBOOK_REQUEST = 'RENAME_NOTEBOOK_REQUEST';
export const RENAME_NOTEBOOK_SUCCESS = 'RENAME_NOTEBOOK_SUCCESS';
export const RENAME_NOTEBOOK_FAILURE = 'RENAME_NOTEBOOK_FAILURE';

function renameNotebookRequest() {
  return {
    type: RENAME_NOTEBOOK_REQUEST
  };
}

function renameNotebookSuccess(payload) {
  return {
    type: RENAME_NOTEBOOK_SUCCESS,
    payload,
  };
}

function renameNotebookFailure(payload) {
  return {
    type: RENAME_NOTEBOOK_FAILURE,
    payload,
  };
}

export const renameNotebook = args => {
  return async dispatch => {
    dispatch(renameNotebookRequest());
    const response = await requests.notebooks.renameNotebook(args);

    response.__args = args;
    if(response.status < 400) {
      dispatch(renameNotebookSuccess(response));
    } else {
      dispatch(renameNotebookFailure(response));
    }
  }
};

export const UPDATE_NOTEBOOK_REQUEST = 'UPDATE_NOTEBOOK_REQUEST';
export const UPDATE_NOTEBOOK_SUCCESS = 'UPDATE_NOTEBOOK_SUCCESS';
export const UPDATE_NOTEBOOK_FAILURE = 'UPDATE_NOTEBOOK_FAILURE';

function updateNotebookRequest() {
  return {
    type: UPDATE_NOTEBOOK_REQUEST
  };
}

function updateNotebookSuccess(payload) {
  return {
    type: UPDATE_NOTEBOOK_SUCCESS,
    payload,
  };
}

function updateNotebookFailure(payload) {
  return {
    type: UPDATE_NOTEBOOK_FAILURE,
    payload,
  };
}

export const updateNotebook = args => {
  return async dispatch => {
    
    dispatch(updateNotebookRequest());
    const response = await requests.notebooks.updateNotebook(args);

    response.__args = args;
    if(response.status < 400) {
      dispatch(updateNotebookSuccess(response));
    } else {
      dispatch(updateNotebookFailure(response));
    }
  }
};

export const DELETE_NOTEBOOK_REQUEST = 'DELETE_NOTEBOOK_REQUEST';
export const DELETE_NOTEBOOK_SUCCESS = 'DELETE_NOTEBOOK_SUCCESS';
export const DELETE_NOTEBOOK_FAILURE = 'DELETE_NOTEBOOK_FAILURE';

function deleteNotebookRequest() {
  return {
    type: DELETE_NOTEBOOK_REQUEST
  };
}

function deleteNotebookSuccess(payload) {
  return {
    type: DELETE_NOTEBOOK_SUCCESS,
    payload,
  };
}

function deleteNotebookFailure(payload) {
  return {
    type: DELETE_NOTEBOOK_FAILURE,
    payload,
  };
}

export const deleteNotebook = args => {
  return async dispatch => {
    dispatch(deleteNotebookRequest());
    const response = await requests.notebooks.deleteNotebook(args);
    response.__args = args;
    if(response.status < 400) {
      dispatch(deleteNotebookSuccess(response));
    } else {
      dispatch(deleteNotebookFailure(response));
    }
  }
};
