import * as user from '../requests/user' 

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

function fetchUserRequest() {
  return {
    type: FETCH_USER_REQUEST,
  };
}

function fetchUserSucces(payload) {
  return {
    type: FETCH_USER_SUCCESS,
    payload,
  };
}

function fetchUserFailure(payload) {
  return {
    type: FETCH_USER_FAILURE,
    payload,
  }
}

export const fetchUser = () => {
  return async dispatch => {
    dispatch(fetchUserRequest());
    const response = await user.get();
    if(response.status < 400) {
      dispatch(fetchUserSucces(response));
    } else { 
      dispatch(fetchUserFailure(response));
    }
  };
};

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

function changePasswordRequest(){
  return {
    type: CHANGE_PASSWORD_REQUEST,
  }
}

function changePasswordSuccess(payload) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload,
  }
}

function changePasswordFailure(payload) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload,
  }
}

export const changePassword = (data) => {
  return async dispatch => {
    dispatch(changePasswordRequest());
    const response = await user.put(data);
    if (response.status < 400) {
      dispatch(changePasswordSuccess(response));
    } else {
      dispatch(changePasswordFailure(response));
    }
  }
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

function resetPasswordRequest(){
  return {
    type: RESET_PASSWORD_REQUEST,
  }
}

function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  }
}

function resetPasswordFailure(payload) {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload,
  }
}

export const resetPassword = (email) => {
  return async dispatch => {
    dispatch(resetPasswordRequest());
    const response = await user.resetPassword(email);
    if (response.status < 400) {
      dispatch(resetPasswordSuccess(response));
    } else {
      dispatch(resetPasswordFailure(response));
    }
  }
}
