import { connect } from 'react-redux';
import RegisterForm from '../components/RegisterForm/RegisterForm';
import { register } from '../actions/auth';

const mapStateToProps = state => ({
   isAuthenticated: state.auth.isAuthenticated,
   isRegisterSuccess: state.auth.isRegisterSuccess,
   isRegisterFailure : state.auth.isRegisterFailure,
});

const mapDispatchToProps = dispatch => ({
  register: (email, password) => dispatch(register(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
