import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever'
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "34px",
  },
  card: {
    // maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },

  iconExecute: {
    padding: 0,
    margin: "4px 0 0 4px"
  },
  button: {
    padding: 0,
  },
  cellTypeButton: {
    height: "24px",
    fontSize: "0.675rem",
  },
});

class CellHeader extends React.Component {
  constructor(props) {
    super(props);

    this.handleCellOptionsClose = this.handleCellOptionsClose.bind(this);
  };

  handleCellOptionsClose(action) {
    const { handleCreateCell, handleDeleteCell, cellId } = this.props;

    return (e) => {
      console.log("ACTION", action);
  
      switch (action) {
        case "ADD_ABOVE":
          handleCreateCell({ index: cellId });
          break;
        case "ADD_BELOW":
          handleCreateCell({ index: cellId + 1 });
          break;
        case "DELETE":
          handleDeleteCell({ index: cellId });
          break;
        default:
          break;
      }
  
      this.setState({ anchorEl: null });
    }
  }

  render() {
    const { 
      classes, 
      cellType, 
      executionCount,
      handleChangeCellTypeCode, 
      handleChangeCellTypeMarkdown, 
      handleExecute,
    } = this.props;

    return (
      <div className={classes.root}>
        <Grid container justify="space-between" className={classes.root}>
          <Grid item >
            <IconButton onClick={handleExecute} classes={{ root: classes.iconExecute }}>
              <PlayCircleFilled />
            </IconButton>
            
          </Grid>
          <div style={{display : 'flex', marginRight : 'auto', marginLeft : '10px', alignSelf : 'center'}}>[ {executionCount} ]</div>
          
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <ToggleButtonGroup value={cellType} exlusive="true" style={{ marginTop: "0px", marginRight: "15px" }}>
              <ToggleButton value="code" classes={{ root: classes.cellTypeButton }} onClick={handleChangeCellTypeCode}>
                code
              </ToggleButton>
              <ToggleButton value="markdown" onClick={handleChangeCellTypeMarkdown} classes={{ root: classes.cellTypeButton }}>
                markdown
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton classes={{ root: classes.button }}>
              <ArrowUpward onClick={this.handleCellOptionsClose("ADD_ABOVE")} />
            </IconButton>
            <IconButton classes={{ root: classes.button }}>
              <ArrowDownward onClick={this.handleCellOptionsClose("ADD_BELOW")} />
            </IconButton>
            <IconButton classes={{ root: classes.button }}>
              <DeleteForever onClick={this.handleCellOptionsClose("DELETE")}></DeleteForever>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CellHeader);
