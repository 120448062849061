import React, { Fragment } from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import Kernel from '../../containers/Kernel';
import Cell from '../Cell/Cell';


export default class NotebookActive extends React.Component {
  constructor(props) {
    super(props);
    
  }

  componentDidUpdate(prevProps) {
    const { 
      notebook, 
      cells, 
      createCell 
    } = this.props;

    if (notebook && !_.isEqual(notebook, prevProps.notebook)) {
      if (notebook.content && !cells.length) {
        createCell({ index: 0 });
      }
    }
  }

  render() {
    const {
      notebook,
      cells,
      createCell,
      updateCell,
      deleteCell,
    } = this.props;

    return (
      notebook
        ? <Fragment>            
            {cells.map((cell, index) => (
              <Cell key={`celll ${cell.__id} ${notebook.name}`}
                    cellId={index}
                    cell={cell}
                    notebookName={notebook.name}
                    createCell={createCell}
                    updateCell={updateCell}
                    deleteCell={deleteCell} />
            ))}
          </Fragment>
        : ''
    );
  }
}
