import React from 'react';
import { TextField, IconButton, Dialog, Button, Slide, withStyles, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },

    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    modal: {
        flexDirection: 'column'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }

});

function Transition(props) {
    return <Slide direction='up' {...props} />;
};

class Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.openAccount,
            password: '',
            oldpassword: '',
            newpassword: '',
            repeatpassword: '',
            passwordDidntMatch: false,
            showPassword: false,
            email: '',
        }

        this.handleChangePassword = this.handleChangePassword.bind(this);
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value,
        passwordDidntMatch : false });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    componentDidMount() {
        const { user } = this.props;

        if (user !== undefined && user !== null) {
            this.setState({
                email: user.email
            })
        }
    }

    handleChangePassword() {
        const { changePassword } = this.props;
        const {oldpassword, newpassword, repeatpassword} = this.state;

        if (newpassword === repeatpassword) {
            changePassword({ password: oldpassword, newPassword: newpassword });
        } else {
            this.setState({
                passwordDidntMatch : true
            })
        }
    }

    render() {
        const { classes, openAccount, closeAccount, user } = this.props;
        const { oldpassword, newpassword, repeatpassword, showPassword, passwordDidntMatch } = this.state;
        
        return (
            <div>
                <Dialog maxWidth='md'
                    fullWidth
                    open={openAccount}
                    onClose={closeAccount}
                    TransitionComponent={Transition}>
                    <DialogTitle id="max-width-dialog-title">Account Settings</DialogTitle>
                    <DialogContent className={classes.dialog}>
                        <div className={classes.root}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <DialogTitle id="max-width-dialog-title">Login</DialogTitle>
                                <TextField label='E-mail'
                                    className={classes.textField}
                                    disabled
                                    defaultValue={user ? user.email : ''}
                                    margin='normal'
                                    variant='standard' />
                            </div>
                            {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <DialogTitle id="max-width-dialog-title">Big data (Apache Spark)</DialogTitle>
                                <Switch value="usebigdata"
                                    style={{ alignSelf: 'center' }} />
                            </div>
                            <DialogContentText>
                                By activating Big Data a new cluster (2-150 machines) will be launched with Spark 2.3.1. By default launched 1 worker, but You can request required amount.
                            </DialogContentText> */}
                            <DialogTitle id="max-width-dialog-title">Change password</DialogTitle>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <TextField id="outlined-adornment-password1"
                                    className={classNames(classes.margin, classes.textField)}
                                    variant="standard"                                    
                                    type={showPassword ? 'text' : 'password'}
                                    label="Old password"
                                    value={oldpassword}
                                    onChange={this.handleChange('oldpassword')} />
                                <TextField id="outlined-adornment-password2"
                                    className={classNames(classes.margin, classes.textField)}
                                    variant="standard"
                                    error={passwordDidntMatch}
                                    type={showPassword ? 'text' : 'password'}
                                    label="New password"
                                    value={newpassword}
                                    onChange={this.handleChange('newpassword')} />
                                <TextField id="outlined-adornment-password3"
                                    className={classNames(classes.margin, classes.textField)}
                                    variant="standard"
                                    error={passwordDidntMatch}
                                    type={showPassword ? 'text' : 'password'}
                                    label="Repeat password"
                                    value={repeatpassword}
                                    onChange={this.handleChange('repeatpassword')} />
                                <IconButton aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>

                                <Button color='secondary'
                                    style={{ margin: '10px' }}
                                    onClick={this.handleChangePassword}>
                                    Change password
                        </Button>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAccount} color="primary" >
                            Close
                    </Button>
                    </DialogActions>
                </Dialog>


            </div>
        )
    }
}

export default withStyles(styles)(Account);