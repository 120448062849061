import * as _ from 'lodash';

export const objectKeysToCamelCase = object => {
  if (_.isPlainObject(object)) {
    return _.transform(object, (aggregator, value, key) => {
      aggregator[_.camelCase(key)] = objectKeysToCamelCase(value);
    });
  }
  if (Array.isArray(object)) {
    return object.map(objectKeysToCamelCase);
  }
  return object;
};

export const objectKeysToSnakeCase = object => {
  if (_.isPlainObject(object)) {
    return _.transform(object, (aggregator, value, key) => {
      aggregator[_.snakeCase(key)] = objectKeysToSnakeCase(value);
    });
  }
  if (Array.isArray(object)) {
    return object.map(objectKeysToSnakeCase);
  }
  return object;
};
