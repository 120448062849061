import React from 'react';
import {
  withStyles,
  Button
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever'
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Input from '@material-ui/core/Input';

const styles = theme => ({
  listbutton: {
    margin: theme.spacing.unit / 4,
    color: theme.palette.primary.main,
    width: "100%",
  },

  attentionbutton: {
    marginBottom: theme.spacing.unit * 4,
    float: "right",
  },

  input: {
    margin: "8px 0",
    padding: "0 0 0 15px",

    fontSize: "0.875rem",
    width: "100%",   

  },
  inputText: {
    textTransform: "uppercase",
    padding: "0 0 8px 0",
    '&:focus': {
      // background: 'blue'
    }
  },
  

  label: {
    justifyContent: "left",
    textTransform : 'initial'
  },

});

class NotebookMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditActive: false,
      isHover: false,
      newName: '',

    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleHoverTrue = this.toggleHoverTrue.bind(this);
    this.toggleHoverFalse = this.toggleHoverFalse.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleConfirmRename = this.handleConfirmRename.bind(this);
    this.handleDeleteNotebook = this.handleDeleteNotebook.bind(this);
  }

  toggleEdit() {  
    this.setState({
      isEditActive: !this.state.isEditActive
    });
  };

  
  toggleHoverFalse() {
    this.setState({
      isHover: false
    })
  }

  toggleHoverTrue() {
    this.setState({
      isHover: true
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      const { notebookName, renameNotebook } = this.props;
      renameNotebook(notebookName, e.target.value);
      this.setState({
        isEditActive: false,        
      })
    }
    else {
      this.setState({
        newName : e.target.value
      })
    }
  };

  handleConfirmRename(){
    const { notebookName, renameNotebook } = this.props;
      renameNotebook(notebookName, this.state.newName);
      this.setState({
        isEditActive: false,

      })
  }

  handleDeleteNotebook() {
    const { notebookName, deleteNotebook } = this.props;

    deleteNotebook(notebookName);
  }

  render() {
    const {
      classes,
      notebookName,
      handleOpenNotebook,
    } = this.props;
    const { isEditActive, isHover } = this.state;

    return (
      <div style={{ position: "relative", marginBottom: "5px", }}>
        {isEditActive ?
          <Input
            defaultValue={notebookName}
            className={classes.input}
            classes={{ input: classes.inputText }}
            onKeyDown={this.handleKeyPress}
          />          
          :
          <Button onClick={handleOpenNotebook(notebookName)}
                  variant="contained"
                  classes={{ label: classes.label }}
                  label={{ float: "left" }}
                  // color="primary"
                  style={{ width: "100%" }}
                  onMouseEnter={this.toggleHoverTrue}
                  onMouseLeave={this.toggleHoverFalse}>
                  {notebookName}
          </Button>
        }
        {isEditActive ? 
        <IconButton
                    style={{ padding: "0px", float: "right", marginTop: "-34px" }}>
                    <Check onClick={this.handleConfirmRename}
                      />
        </IconButton>
      : ''}
        {isHover ?
          <div>
            < IconButton onMouseEnter={this.toggleHoverTrue}
                          onMouseLeave={this.toggleHoverFalse}
                          style={{ padding: "0px", float: "right", marginRight: "24px", marginTop: "-30px" }}>
              <Edit onClick={this.toggleEdit} />
            </IconButton>
            <IconButton style={{ padding: "0px", float: "right", marginTop: "-30px" }}>
              <DeleteForever onClick={this.handleDeleteNotebook}
                              onMouseEnter={this.toggleHoverTrue}
                              onMouseLeave={this.toggleHoverFalse} />
            </IconButton>
          </div>
          : ""
        }
      </div >
    );
  }
}

export default withStyles(styles)(NotebookMenuItem);
