import {
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_FAILURE,
  POST_APPLICATION_REQUEST,
  POST_APPLICATION_SUCCESS,
  POST_APPLICATION_FAILURE,
  CHANGE_STATUS_APPLICATION_REQUEST,
  CHANGE_STATUS_APPLICATION_SUCCESS,
  CHANGE_STATUS_APPLICATION_FAILURE,
  UPDATE_APPLICATION_STATE,
  FETCH_APPLICATION_SPARK_REQUEST,
  FETCH_APPLICATION_SPARK_SUCCESS,
  FETCH_APPLICATION_SPARK_FAILURE,
  POST_APPLICATION_SPARK_REQUEST,
  POST_APPLICATION_SPARK_SUCCESS,
  POST_APPLICATION_SPARK_FAILURE,
  CHANGE_STATUS_APPLICATION_SPARK_REQUEST,
  CHANGE_STATUS_APPLICATION_SPARK_SUCCESS,
  CHANGE_STATUS_APPLICATION_SPARK_FAILURE,
  UPDATE_APPLICATION_SPARK_MASTER,
  UPDATE_APPLICATION_SPARK_WORKER,
  SERVICE_UNAVAILABLE,
} from '../actions/application';
import { LOGOUT } from '../actions/auth';

const initialState = {
  data: null,
  spark: {
    master: null,
    worker: null,
  },
  isServiceUnavailable: false,
  isFetchApplicationRequest: false,
  isFetchApplicationSuccess: false,
  isFetchApplicationFailure: false,
  isPostApplicationRequest: false,
  isPostApplicationSuccess: false,
  isPostApplicationFailure: false,
  isChangeStatusAppicationRequest: false,
  isChangeStatusAppicationSuccess: false,
  isChangeStatusAppicationFailure: false,
  isFetchApplicationSparkRequest: false,
  isFetchApplicationSparkSuccess: false,
  isFetchApplicationSparkFailure: false,
  isPostApplicationSparkRequest: false,
  isPostApplicationSparkSuccess: false,
  isPostApplicationSparkFailure: false,
  isChangeStatusAppicationSparkRequest: false,
  isChangeStatusAppicationSparkSuccess: false,
  isChangeStatusAppicationSparkFailure: false,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICATION_REQUEST:
      return {
        ...state,
        isFetchApplicationRequest: true,
        isFetchApplicationSuccess: false,
        isFetchApplicationFailure: false,
      };
    case FETCH_APPLICATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetchApplicationRequest: false,
        isFetchApplicationSuccess: true,
        isFetchApplicationFailure: false,
        isServiceUnavailable: false,
      };
    case FETCH_APPLICATION_FAILURE:
      return {
        ...state,
        isFetchApplicationRequest: false,
        isFetchApplicationSuccess: false,
        isFetchApplicationFailure: true,
        isServiceUnavailable: action.payload.status === 503,
      };
    case POST_APPLICATION_REQUEST:
      return {
        ...state,
        isPostApplicationRequest: true,
        isPostApplicationSuccess: false,
        isPostApplicationFailure: false,
      };
    case POST_APPLICATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isPostApplicationRequest: false,
        isPostApplicationSuccess: true,
        isPostApplicationFailure: false,
      };
    case POST_APPLICATION_FAILURE:
      return {
        ...state,
        isPostApplicationRequest: false,
        isPostApplicationSuccess: false,
        isPostApplicationFailure: true,
      };
    case CHANGE_STATUS_APPLICATION_REQUEST:
      return {
        ...state,
        isChangeStatusAppicationRequest: true,
        isChangeStatusAppicationSuccess: false,
        isChangeStatusAppicationFailure: false,
      };
    case CHANGE_STATUS_APPLICATION_SUCCESS:
      return {
        ...state,
        isChangeStatusAppicationRequest: false,
        isChangeStatusAppicationSuccess: true,
        isChangeStatusAppicationFailure: false,
      };
    case CHANGE_STATUS_APPLICATION_FAILURE:
      return {
        ...state,
        isChangeStatusAppicationRequest: false,
        isChangeStatusAppicationSuccess: false,
        isChangeStatusAppicationFailure: true,
      };
    case FETCH_APPLICATION_SPARK_REQUEST:
      return {
        ...state,
        isFetchApplicationSparkRequest: true,
        isFetchApplicationSparkSuccess: false,
        isFetchApplicationSparkFailure: false,
      };
    case FETCH_APPLICATION_SPARK_SUCCESS:
      return {
        ...state,
        spark: {
          master: action.payload.data ? action.payload.data.master : null,
          worker: action.payload.data ? action.payload.data.worker : null,
        },
        isFetchApplicationSparkRequest: false,
        isFetchApplicationSparkSuccess: true,
        isFetchApplicationSparkFailure: false,
      };
    case FETCH_APPLICATION_SPARK_FAILURE:
      return {
        ...state,
        isFetchApplicationSparkRequest: false,
        isFetchApplicationSparkSuccess: false,
        isFetchApplicationSparkFailure: true,
      };
    case POST_APPLICATION_SPARK_REQUEST:
      return {
        ...state,
        isPostApplicationRequest: true,
        isPostApplicationSuccess: false,
        isPostApplicationFailure: false,
      };
    case POST_APPLICATION_SPARK_SUCCESS:
      return {
        ...state,
        spark: {
          ...state.spark,
          master: action.payload.data,
        },
        isPostApplicationRequest: false,
        isPostApplicationSuccess: true,
        isPostApplicationFailure: false,
      };
    case POST_APPLICATION_SPARK_FAILURE:
      return {
        ...state,
        isPostApplicationRequest: false,
        isPostApplicationSuccess: false,
        isPostApplicationFailure: true,
      };
    case CHANGE_STATUS_APPLICATION_SPARK_REQUEST:
      return {
        ...state,
        isChangeStatusAppicationSparkRequest: true,
        isChangeStatusAppicationSparkSuccess: false,
        isChangeStatusAppicationSparkFailure: false,
      };
    case CHANGE_STATUS_APPLICATION_SPARK_SUCCESS:
      return {
        ...state,
        isChangeStatusAppicationSparkRequest: false,
        isChangeStatusAppicationSparkSuccess: true,
        isChangeStatusAppicationSparkFailure: false,
      };
    case CHANGE_STATUS_APPLICATION_SPARK_FAILURE:
      return {
        ...state,
        isChangeStatusAppicationSparkRequest: false,
        isChangeStatusAppicationSparkSuccess: false,
        isChangeStatusAppicationSparkFailure: true,
      };
    case UPDATE_APPLICATION_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          state: action.payload.state === 'TASK_KILLED' ? null : action.payload.state,
        }
      };
    case UPDATE_APPLICATION_SPARK_MASTER:
      return {
        ...state,
        spark: {
          ...state.spark,
          master: { ...action.payload },
        }
      };
    case UPDATE_APPLICATION_SPARK_WORKER:
      let isUpdated = false;
      const workerTasks = (state.spark.worker && Array.isArray(state.spark.worker.tasks)) 
          ? state.spark.worker.tasks.slice() 
          : [];
      for (let task in workerTasks) {
        if (workerTasks[task].taskId === action.payload.taskId) {
          isUpdated = true;
          workerTasks[task] = Object.assign(workerTasks[task], action.payload);
        }
      }
      if (!isUpdated) {
        workerTasks.push(action.payload);
      }
      return {
        ...state,
        spark: {
          ...state.spark,
          worker: { ...state.spark.worker, tasks: workerTasks },
        }
      };
    case SERVICE_UNAVAILABLE:
      return {
        ...state,
        isServiceUnavailable: true,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
