import React from 'react';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import NotebookMenuList from '../NotebookMenuList/NotebookMenuList';
import FileUploader from '../FileUploader/FileUploader';
import requests from '../../requests';

const styles = theme => ({
  attentionbutton: {
    marginBottom: theme.spacing.unit * 4,
    float: "right",
    padding: 0,
    marginTop: "4px",
  },

  header6: {
    display: "inline-block"
  },
});

class Leftmenu extends React.Component {
  render() {
    const { classes } = this.props;
    const {
      notebooks,
      renameNotebook,
      deleteNotebook,
      handleOpenNotebook,
      handleCreateNotebook,
      fetchNotebooks,
    } = this.props;

    return (
      <div className="">
        <Typography variant="h6" classes={{ root: classes.header6 }}>Notebooks</Typography>
        <IconButton onClick={handleCreateNotebook}
                    classes={{ root: classes.attentionbutton }}
                    color="primary">
          <AddIcon />
        </IconButton>
        {notebooks &&
          <div >
            <NotebookMenuList notebooks={notebooks}
                              renameNotebook={renameNotebook}
                              deleteNotebook={deleteNotebook}
                              handleOpenNotebook={handleOpenNotebook} />
            <FileUploader mimetypes={['application/vnd.jupyter', 'application/vnd.jupyter.cells', 'application/x-ipynb+json']}
                          extensions={['ipynb']}
                          maxSize={268435456}
                          uploadRequest={requests.notebooks.uploadNotebook}
                          onUploadSuccess={fetchNotebooks} />
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(Leftmenu);
