import './Workspace.css';
import React, { Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  AppBar,
  Tab,
  Typography,
  withStyles,
  DialogTitle,
  DialogContent,
  
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrow from '@material-ui/icons/PlayArrow';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import requests from '../../requests';
import { CONTAINER_RUNNING } from '../../constants/containers';
import NotebookActive from '../../containers/NotebookActive';
import FileUploader from '../FileUploader/FileUploader';
import WorkspaceHeader from '../../containers/WorkspaceHeader';
import LeftMenu from '../Leftmenu/Leftmenu';
import DatasetMenuList from '../DatasetMenuList/DatasetMenuList';
import ServersMenuList from '../../containers/ServersMenuList';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 5 }}>
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    margin: "0px"
  },
  gridContainer: {
    margin: "-4px",
    width: "100%",
  },
  listbutton: {
    margin: theme.spacing.unit / 4,
    width: "100%",
    padding: "1px",
    minHeight: "28px",
  },
  attentionbutton: {
    float: "right",
    marginRight: "14px",
    padding: "0px",
  },
  tabItem: {
    minWidth: "0px",
    // paddingLeft : '12px',
    // paddingRight : '12px',
  },
  labelContainer : {
    paddingLeft : '12px',
    paddingRight : '12px',
  },
  drawerlist: {
    width: "300px",
  },
  buttonFloatRight: {
    float: "right",
    padding: "6px",
    // margin: theme.spacing.unit,
  },
  typographyFloatLeft: {
    float: "left",
  },
  tabContainer: {
    padding: "0px 0px 0px 0px"
  },
  containerInfo : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'flex-end',
  }

});

class Workspace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openedNotebook: null,
      cells: null,
      tabindex: 0,
      right: false, // this is "side" in toggleDrawer      
    };

    this.fetchApplicationTimeoutId = null;

    // NOTEBOOKS HANDLERS
    this.renameNotebook = this.renameNotebook.bind(this);
    this.deleteNotebook = this.deleteNotebook.bind(this);
    this.handleOpenNotebook = this.handleOpenNotebook.bind(this);
    this.handleCreateNotebook = this.handleCreateNotebook.bind(this);
    this.handleUpdateNotebook = this.handleUpdateNotebook.bind(this);
    
    // CONTAINERS HANDLERS
    this.handleChangeContainerState = this.handleChangeContainerState.bind(this);
  };

  componentDidMount() {
    const { fetchApplication } = this.props;

    fetchApplication();
  }

  componentDidUpdate(prevProps) {
    const { isServiceUnavailable, fetchApplication } = this.props;

    if (isServiceUnavailable && !prevProps.isServiceUnavailable) {
      this.fetchApplicationTimeoutId = setInterval(() => fetchApplication(), 3000);
    }
    if (!isServiceUnavailable && this.fetchApplicationTimeoutId) {
      clearInterval(this.fetchApplicationTimeoutId);
      this.fetchApplicationTimeoutId = null;
    }
  }

  renameNotebook(name, newName) {
    const { renameNotebook } = this.props;

    renameNotebook({ name, newName });
  }

  deleteNotebook(name) {
    const { deleteNotebook, isDeleteNotebookRequest } = this.props;

    !isDeleteNotebookRequest && deleteNotebook({ name });
  }

  handleCreateNotebook() {
    const { isCreateNotebookRequest, createNotebook } = this.props;

    !isCreateNotebookRequest && createNotebook();
  }

  handleUpdateNotebook() {
    const { activeNotebook, updateNotebook, isUpdateNotebookRequest } = this.props;

    if (activeNotebook && !isUpdateNotebookRequest) {
      updateNotebook({
        name: activeNotebook.name,
        content: activeNotebook.content,
      });
    }
  }

  handleOpenNotebook(name) {
    return () => {
      const { fetchNotebook, isFetchNotebookRequest } = this.props;

      !isFetchNotebookRequest && fetchNotebook({ name });
    };
  } 

  // change tab-header
  handleChangeTab = (event, tabindex) => {
    this.setState({ tabindex });
  };
  // change tab
  handleChangeIndex = index => {
    this.setState({ tabindex: index }); /*?*/
  };

  handleChangeContainerState(action) {
    const {
      isChangeStatusApplicationRequest,
      changeStatusApplication, 
    } = this.props;

    return async () => {
      !isChangeStatusApplicationRequest && changeStatusApplication({ action });
    };
  }

  
  render() {
    const { tabindex } = this.state;
    const {
      classes,
      application,
      notebook,
      notebooks,
      datasets,
      fetchNotebooks,
      logout,
      user,
      isChangeStatusApplicationRequest,
      changeStatusApplication,
      isServiceUnavailable
    } = this.props;
    

    return (
      <div className={classes.root}>
        <Grid container spacing={0} style={{ padding: "20px", height : '95%'}}>        
          <WorkspaceHeader logout={logout} 
                           application={application}                           
                           user={user}
                           notebook={notebook}
                           isChangeStatusApplicationRequest={isChangeStatusApplicationRequest}
                           changeStatusApplication={changeStatusApplication}
                           CONTAINER_RUNNING={CONTAINER_RUNNING}
                           handleUpdateNotebook={this.handleUpdateNotebook} />
          
          <Grid item xs={12} sm={3} style={{ width: "300px", maxWidth: "300px" }}>
          
            {application &&
              <Fragment>                
                <div className={classes.containerInfo}>                  
                  <div style={{display : 'flex', justifyContent : 'flex-start', marginRight : 'auto'}}>
                  <FiberManualRecord style={{padding : '0', 
                                      display : 'inline-flex', 
                                      marginRight : 'auto',
                                      color : application.state === 'TASK_RUNNING' ? 'green' : 'red'
                                      }}/>
                  <Tooltip title='TASK_RUNNING - ready to execute code; TASK_KILLING/STARTING - container terminating/starting in progress; NOT RUNNING - server suspended' 
                           placement='right-end'>
                    <Typography style={{display : 'flex', marginRight : 'auto', marginLeft: '5px', alignSelf : 'center'}} 
                                variant="body1">
                                {application.state ? application.state : 'NOT RUNNING'}
                    </Typography>
                  </Tooltip>
                  </div>
                  <IconButton style={{padding : '0', display : 'inline-flex'}}
                              disabled={application.state !== CONTAINER_RUNNING}
                              onClick={this.handleChangeContainerState('stop')}>
                      <StopIcon/>
                  </IconButton>
                  <IconButton style={{padding : '0', display : 'inline-flex'}}
                              disabled={application.state === CONTAINER_RUNNING} 
                              onClick={this.handleChangeContainerState('start')}>
                      <PlayArrow/>
                  </IconButton>
                </div>
                <br/>
              </Fragment>
            }

            <AppBar position="static" color="default" style={{ marginBottom: "10px" }}>
              <Tabs value={tabindex}
                onChange={this.handleChangeTab}>
                <Tab label="Notebooks" classes={{ root : classes.tabItem, labelContainer: classes.labelContainer }}/>
                <Tab label="Datasets" classes={{ root : classes.tabItem, labelContainer: classes.labelContainer }} />
                <Tab label="Servers" classes={{ root : classes.tabItem, labelContainer: classes.labelContainer }} />
              </Tabs>
            </AppBar>
            <SwipeableViews index={tabindex}
                            onChangeIndex={this.handleChangeIndex}
                            style={{background : 'whitesmoke', height : '90%', boxShadow : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'}}>
              <TabContainer className={classes.tabContainer}>
                <LeftMenu application={application}
                          notebooks={notebooks}
                          renameNotebook={this.renameNotebook}
                          deleteNotebook={this.deleteNotebook}
                          handleCreateNotebook={this.handleCreateNotebook}
                          handleOpenNotebook={this.handleOpenNotebook}
                          fetchNotebooks={fetchNotebooks} />
              </TabContainer>
              <TabContainer>
                <Typography variant="h6">Datasets</Typography>
                {
                  datasets ? <DatasetMenuList datasets={datasets}/> : ""
                }
                
                <FileUploader mimetypes={['text/plain', 'text/csv', 'application/json', 'application/vnd.ms-excel']}
                              extensions={['csv', 'txt', 'json']}
                              maxSize={2147483648}
                              onUploadSuccess={fetchNotebooks}
                              uploadRequest={requests.datasets.uploadDataset} />
              </TabContainer>
              <TabContainer className={classes.tabContainer}>
                <ServersMenuList handleChangeContainerState={this.handleChangeContainerState} />
              </TabContainer>
            </SwipeableViews>
          </Grid>
          <Grid item xs={12} sm={12} style={{ padding: "3px 2px 0px 10px", overflow : 'auto', flex : 1, flexDirection : 'column', display : 'flex', height : '100%' }}>
            <NotebookActive/>
          </Grid>
          
        </Grid>        
        <Dialog open={isServiceUnavailable}>
          <DialogTitle>
              Service unavailable. Please try again later.
          </DialogTitle>
          <DialogContent>
          <Typography gutterBottom>
              We`re working on improving the service. It will take some time
            </Typography>            
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(Workspace);
