import request from '../utils/request';

export const login = async (email, password) => {
  const url = 'auth/sign-in';
  return await request(url, 'POST', { email, password });
};

export const register = async (email, password) => {
  const url = 'auth/sign-up';
  return await request(url, 'POST', { email, password });
};
