import request from '../utils/request'
import { getStateUser } from '../utils/store';
import { objectKeysToSnakeCase } from '../utils/object';


export const fetchNotebooks = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks`;
  return await request(url);
};

export const fetchNotebook = async ({ name }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${name}`;
  return await request(url);
};

export const createNotebook = async () => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks`;
  return await request(url, 'POST', { ext: '.ipynb', type: 'notebook' });
};

export const renameNotebook = async ({ name, newName }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${name}`;
  return await request(url, 'PATCH', { path: newName });
};

export const updateNotebook = async ({ name, content }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${name}`;
  const data = objectKeysToSnakeCase({ type: 'notebook', name, content });
  return await request(url, 'PUT', data);
};

export const deleteNotebook = async ({ name }) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/${name}`;
  return await request(url, 'DELETE');
};

export const uploadNotebook = async (formData) => {
  const user = getStateUser();
  const url = `users/${user.id}/notebooks/upload`;
  return await request(url, 'POST', formData);
};
