import { connect } from 'react-redux';

import { 
  fetchApplication,
  changeStatusApplication,
  changeStatusApplicationSpark,
  postApplicationSpark,
  fetchApplicationSpark
} from '../actions/application';

import ServersMenuList from '../components/ServersMenuList/ServersMenuList';

const mapStateToProps = state => ({
  application: state.application.data,
  spark : state.application.spark,
  isFetchApplicationSuccess: state.application.isFetchApplicationSuccess,
  isChangeStatusApplicationRequest: state.application.isChangeStatusApplicationRequest, 
  
});

const mapDispatchToProps = dispatch => ({  
  fetchApplication: () => dispatch(fetchApplication()),
  changeStatusApplication: args => dispatch(changeStatusApplication(args)),
  changeStatusApplicationSpark : args => dispatch(changeStatusApplicationSpark(args)),
  postApplicationSpark : args => dispatch(postApplicationSpark(args)),
  fetchApplicationSpark : args => dispatch(fetchApplicationSpark(args)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ServersMenuList);
